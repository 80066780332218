
var _Environments = {
  production: {
    JWPLAYER:'95riWD5s.js',
    BASE_URL: "https://api.wexer.com/",
    API_KEY: '1234123412341234',
    API_SECRET: '573572796441127398913495789868468206481',
  },
  development: {
    JWPLAYER:'HofBP5zj.js',
    BASE_URL: "https://ff-int.api.wexer.com/",
    API_KEY: "1234123412341234",
    API_SECRET: "573572796441127398913495789868468206481",
  },
  stage: {
    JWPLAYER:'wUkkXJdm.js',
    BASE_URL: "https://stage.api.wexer.com/",
    API_KEY: "1234123412341234",
    API_SECRET: "573572796441127398913495789868468206481",
  },
};

function getEnvironment() {
  const PLATFORM = process.env.REACT_APP_PLATFORM;

  let platform  //getPlatform();
  if (PLATFORM === "FF-INT") {
    platform = "development";
  } else if (PLATFORM === "STAGE") {
    platform = "stage";
  } else if (PLATFORM === "PROD") {
    platform = "production";
  }

  return _Environments[platform];
}

var Environment = getEnvironment();
module.exports = Environment;

