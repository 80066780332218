import React from "react"

function MoreVertical(){
    const config = require("../assets/config.json")
return(
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
    <defs>
        <path id="prefix__a" d="M2.968 22.488C1.328 22.488 0 21.15 0 19.5s1.329-2.988 2.968-2.988c1.64 0 2.968 1.338 2.968 2.988s-1.329 2.988-2.968 2.988zm0-1.635c.743 0 1.345-.605 1.345-1.353s-.602-1.353-1.345-1.353-1.344.605-1.344 1.353.601 1.353 1.344 1.353zm0-6.365C1.328 14.488 0 13.15 0 11.5s1.329-2.988 2.968-2.988c1.64 0 2.968 1.338 2.968 2.988s-1.329 2.988-2.968 2.988zm0-1.635c.743 0 1.345-.605 1.345-1.353s-.602-1.353-1.345-1.353-1.344.605-1.344 1.353.601 1.353 1.344 1.353zm0-6.365C1.328 6.488 0 5.15 0 3.5S1.329.512 2.968.512c1.64 0 2.968 1.338 2.968 2.988S4.607 6.488 2.968 6.488zm0-1.635c.743 0 1.345-.605 1.345-1.353S3.71 2.147 2.968 2.147 1.624 2.752 1.624 3.5s.601 1.353 1.344 1.353z"/>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(12 3)">
        <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a"/>
        </mask>
        <g fill={config.primaryColor} mask="url(#prefix__b)">
            <path d="M0 0L30 0 30 30 0 30z" transform="translate(-12 -3)"/>
        </g>
    </g>
</svg>
)
}

export default MoreVertical

