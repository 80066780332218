import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/updateObject'


const initialState = {
    LoadEvent:false,
    loaded:false,
    tenantConfigLoaded:false,
    isUserLoaded:false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LOAD_EVENT:
        return updateObject(state,action.payLoad);
        default:
            return state;
    }
};
export default reducer; 