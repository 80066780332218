import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import CardShimmer from './CardShimmer';

const ChannelsShimmer = (props) => {
    return (
        <div data-testid={"channelShimmer"} className="liveShimmer mobileScrollParent m-t-15">
            <Skeleton variant="text" width={'30%'} height={25} />
            <Skeleton variant="text" width={'40%'} height={15} />
            <CardShimmer noOfCards={[1,2,3,4,5]} footer={false} />
        </div>
    );
}
export default ChannelsShimmer;
