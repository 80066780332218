import React from "react"

function Timer(){
    const color = require("../assets/config.json").primaryColor.slice(1)
    const src = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="34" height="34" viewBox="0 0 34 34">
    <defs>
        <path id="a" d="M22.59 7.779l.825-.797c.23.103.51.064.7-.119l.147-.142a.588.588 0 0 0 0-.85l-1.057-1.022a.637.637 0 0 0-.88 0l-.146.142a.588.588 0 0 0 0 .85l.032.032-.763.738A12.61 12.61 0 0 0 14.13 3.47v-.736h.11c.572 0 1.035-.448 1.035-1V1.4c0-.553-.464-1-1.034-1h-2.483c-.572 0-1.035.448-1.035 1v.333c0 .553.463 1 1.035 1h.11v.732C5.777 3.992.896 8.783.553 14.802c-.38 6.636 4.896 12.331 11.76 12.698.234.012.465.018.697.018 6.61 0 12.073-5.001 12.437-11.387.18-3.154-.918-6.094-2.856-8.352zm1.316 8.23c-.33 5.791-5.449 10.281-11.508 9.961-6.013-.321-10.635-5.31-10.303-11.124C2.415 9.252 7.2 4.87 12.99 4.87c.204 0 .406.005.612.016 6.013.32 10.636 5.311 10.304 11.123zm-8.926.184c0 1.058-.886 1.914-1.98 1.914-1.093 0-1.98-.856-1.98-1.914 0-.866.597-1.598 1.415-1.833V8.6c0-.302.253-.547.565-.547.313 0 .566.245.566.547v5.76c.818.236 1.414.967 1.414 1.833z"/>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(4 3)">
        <mask id="b" fill="%23fff">
            <use xlink:href="%23a"/>
        </mask>
        <g fill="%23${color}" mask="url(%23b)">
            <path d="M-4-3h34v34H-4z"/>
        </g>
    </g>
</svg>`
return(
<img className="DoneTimer"
src={`data:image/svg+xml;utf8,${src}`}
alt="Done-Timer"
/>
)}

export default Timer
