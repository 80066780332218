import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from "@material-ui/core";
import CardShimmer from './CardShimmer';

const CollectionClassesShimmer = (props) => {
    return (
        <Grid item lg={12} xs={12} sm={12} md={12}>
            <div data-testid={"collectionClassesShimmer"} className="collectionShimmer mobileScroll m-t-30 m-b-xs-10">
                <Skeleton variant="text" width={'30%'} height={25} />
                <Skeleton variant="text" width={'40%'} height={15} />
                <Skeleton variant="text" width={'30%'} height={13} />
                <CardShimmer noOfCards={[1,2,3,4,5]} footer={true} />
            </div>
        </Grid>
    );
}
export default CollectionClassesShimmer;
