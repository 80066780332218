// import { useEffect } from "react";
// import { FirebaseApp } from "../../FirebaseApp";

const useInternetSpeedMeter = (
    { 
        thresholdUnit='megabyte',
        imageUrl="https://res.cloudinary.com/demo/image/upload/koala.jpg",
        eventId=""
      }
) => {
  /* const firebaseApp = new FirebaseApp();
  
  useEffect(() => {
    const videoTrace = `video_eventId_${eventId}`;
    firebaseApp.setUp(videoTrace);
    firebaseApp.traceData.start();
    return () => {
      firebaseApp.traceData.stop();
    }
  }, []) */
  

    const getFileSize= async ()=>{
      const fileImg = await fetch(imageUrl).then(r => r.blob());
      return fileImg.size;
    }
    const MeasureConnectionSpeed = () => new Promise((resolve,reject)=>{
      try{
          let startTime, endTime;
          let download = new Image();
          startTime = (new Date()).getTime();
    
          const cacheBuster = "?nnn=" + startTime;
          download.src = imageUrl + cacheBuster;
    
          download.onload = function (d) {
              endTime = (new Date()).getTime();
              showResults(startTime , endTime);
          }
          
          download.onerror = function (err, msg) {
              console.log('err',err)
    
        }
      const showResults = (startTime , endTime) => {
      getFileSize().then((fileSize)=>{
        const duration = (endTime - startTime) / 1000;
  
        const bitsLoaded = fileSize * 8;
        const speedBps = (bitsLoaded / duration).toFixed(2);
        const speedKbps = (speedBps / 1024).toFixed(2);
        const speedMbps = (speedKbps / 1024).toFixed(2);
        setNetworStatus(speedMbps)
      })
      }
      const setNetworStatus = (speedMbps) => {
        if( thresholdUnit === 'megabyte'){
          resolve(speedMbps)
        }
        else {
          console.log('Invalid thresholdUnit')
        }
      }
    }
    catch(e){
      reject(0)
    }
  });
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  const getVideoDetails = async (player)=>{
    // const videoEle = player.getContainer().querySelectorAll("video")[0];
    // bandwidthEstimate in bits per second
    // buffer percentage (0-100)
    const speedData = await MeasureConnectionSpeed();
    // setInterval(()=>{
    //   try{
    //   const {bandwidthEstimate = 0 } = player.getConfig(),
    //     bandwidthInBytes =  formatBytes(bandwidthEstimate),
    //     buffer = player.getConfig().buffer.toFixed(2);
    //     firebaseApp.setAttribute({key:"bandwidthInBytes",value:bandwidthInBytes});
    //     firebaseApp.setAttribute({key:"buffer",value:buffer});
    //     firebaseApp.setAttribute({key:"speedData",value:speedData});
    //   }
    //   catch(e){}
    // },10000);
  }
  return {MeasureConnectionSpeed,getVideoDetails};
};
class UseHandelSeekBar {
  #supposedCurrentTime = 0;
  #video = null;
  handelEvents(_video){
    const vidEle = _video.querySelectorAll("video")[0];
    this.#video = vidEle;
    //console.log(this.#video);
  }
  set Video(_video=null){
     this.#video= _video;
  }
  get Video(){
    return this.#video;
  }
  get isIos(){
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }
  addEvents(){
      this.#video.addEventListener('timeupdate',this.timeupdate.bind(this))
      this.#video.addEventListener('seeking',this.seeking.bind(this))
      this.#video.addEventListener('ended',this.ended.bind(this))
  }
  removeEvents(){
    this.#video.removeEventListener('timeupdate',this.timeupdate)
    this.#video.removeEventListener('seeking',this.seeking)
    this.#video.removeEventListener('ended',this.ended)
  }
  timeupdate(){
    if (!this.#video.seeking) {
      this.#supposedCurrentTime = this.#video.currentTime;
    }
    if(document.fullscreenElement === null){
      this.removeEvents();
    }
  }
  seeking(){
    var delta = this.#video.currentTime - this.#supposedCurrentTime;
    if (Math.abs(delta) > 0.01) {
      this.#video.currentTime = this.#supposedCurrentTime;
    }
  }
  ended(){
    this.#supposedCurrentTime = 0;
  }
}
const useHandelSeekBar = new UseHandelSeekBar();
export  {useInternetSpeedMeter,useHandelSeekBar};