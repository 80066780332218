import React from "react";
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux'


function Error() {
    const message = useSelector(state => state.auth.errorMessage)
    return (
        <div className="page-container floatLeft" style={{paddingTop: "32px"}}>
            <Typography variant="h4">
                {message}
            </Typography>
        </div>
    );
}

export default Error
