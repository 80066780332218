import React, { useEffect } from 'react'
import { withRouter } from 'react-router';
import Workout from "../../hoc/WorkoutContainer"
import Routes from '../../components/Router/Routes'
import logger from "../../utils/logger"
import { useClubCardNew } from "../../hooks/useClubCardNew"
 

function ClubCardNew(props) {

    const { useClubCardNewState, useClubCardNewDispatch } = useClubCardNew();
    const { isAuthenticated } = useClubCardNewState();
    const { redirectForSSOClinent } = useClubCardNewDispatch();
    const { clubData,liveStream,isMoreClassSuggetion } = props


    const getMaxResolutionThumbnail = (picturesObj) => {
        if (picturesObj.length === 0) {
            return;
        }

        var max = picturesObj[0].height;
        var maxIndex = 0;

        for (var i = 1; i < picturesObj.length; i++) {
            if (picturesObj[i].height > max) {
                maxIndex = i;
                max = picturesObj[i].height;
            }
        }

        return maxIndex;
    }
    const onClubCardClicked = () => {
        logger(clubData);
        if (!isAuthenticated) {
            var callback = () => {
                props.history.push({ pathname: "/signin" });
              }
              redirectForSSOClinent(callback)
            return
        }
        let video = {
            id: clubData.videoID ? clubData.videoID : clubData.id,
            creationDate: clubData.created_Time ? clubData.created_Time : "",
            trailerLinkWeb: clubData.trailerLink,
            description: clubData.description,
            title: clubData.name,
            thumbnail: clubData.pictures ? clubData.pictures.sizes[getMaxResolutionThumbnail(clubData.pictures.sizes)].link : "",
            durationSecond: clubData.duration,
            fav:clubData.favourite,
            equipmentNames:clubData.equipmentNames,
            level:clubData?.level,
            scheduleDate:clubData.created_Time ? clubData.created_Time : "",
            providerId:clubData?.providerId,
            providerName:clubData?.providerName
        };
        if(props?.handelClick){
            props.handelClick(video?.id);
        }
        props.history.push({
            pathname: `${Routes.connectClassPlayerRoute}${video.id}${props.liveStream ? '?event=' + props.eventId : ""}`,
            state: { selectedItem: video, isClubConnect: true , isLiveStream : liveStream },
        });
    }

    const checkFavStatus = () => {
            clubData.favourite = !clubData.favourite
      }

    return (
        <div>
            <Workout
                cardType="channel-page-card"
                collectionType="featured"
                displayDate={clubData.created_Time}
                trailerLinkWeb={clubData.trailerLink}
                history={props.history}
                showFav={isAuthenticated ? true : false}
                thumbnail={clubData.pictures ? (clubData.pictures.sizes ? clubData.pictures.sizes[getMaxResolutionThumbnail(clubData.pictures.sizes)].link : "") : ""}
                title={clubData.name}
                duration={clubData.duration
                    ? Math.ceil(clubData.duration / 60)
                    : 55}
                description={clubData.description}
                show={true}
                id={clubData.videoID ? clubData.videoID : clubData.id}
                clicked={onClubCardClicked}
                level={clubData.level}
                isClubConnect={true}
                isLiveStream={liveStream}
                isMoreClassSuggetion={isMoreClassSuggetion}
                isFav={clubData.favourite}
                favCallback={() => { checkFavStatus() }}
            />
        </div>
    )
}

export default withRouter(ClubCardNew)
