import * as actionTypes from './actionTypes'
export const setErrorEventLoaded = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.LOAD_EVENT,
            payLoad:{
                error:false,
                }
           });
       };
   };
export const setUserEventLoaded = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.LOAD_EVENT,
            payLoad:{
                isUserLoaded:false,
                }
        });
    };
};
export const setTenantConfigEventLoaded = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.LOAD_EVENT,
            payLoad:{
                tenantConfigLoaded:true,
                }
        });
    };
};
export const setTenantConfigEventTime = (tenantConfigResponseTime) => {
    return dispatch => {
        dispatch({
            type: actionTypes.LOAD_EVENT,
            payLoad:{
                    tenantConfigResponseTime,
                }
        });
    };
};

export const setAppEventLoaded = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.LOAD_EVENT,
            payLoad:{loaded: true}
        });
    };
};
export const setAppLoadStartEvent = (appLoadStartTime) => {
    return dispatch => {
        dispatch({
            type: actionTypes.LOAD_EVENT,
            payLoad:{appLoadStartTime}
        });
    };
};
export const setAppLoadEndEvent = () => {
    return dispatch => {
        const appLoadEndTime = new Date().getTime();
        dispatch({
            type: actionTypes.LOAD_EVENT,
            payLoad:{appLoadEndTime,LoadEvent: true}
        });
    };
};
export const setAppLoadEndErrorEvent = (errorMessage) => {
    return dispatch => {
        const appLoadEndTime = new Date().getTime();
        dispatch({
            type: actionTypes.LOAD_EVENT,
            payLoad:{
                appLoadEndTime,
                error:true,
                errorMessage,
                LoadEvent: true
            }
        });
    };
};