import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
    selectedFilters: [],
    filteredSkill: [],
    filteredDuration: '',
    filteredKeywords: [],
    filteredFocusArea: [],
    filteredEquipments: [],
    filteredIntensity: [],
    filteredLanguage: '',
    filteredSubCategory: '',
    filterSortBy: '',
    filterSearchText: '',
    filteredClassSearchData: [],
    filteredTopKeywords: [],
    loading: false,
    pageCount: 0,
    paginatedData: [],
    classesSearchDataOnDemand: [],
    tempClassSearchDataOnDemand: [],
    topKeywordsOnDemand: [],
};

const setSelectedFilters = (state, action) => {
    return updateObject(state, {
        selectedFilters: action.value,
    });
};

const setFilterSkill = (state, action) => {
    return updateObject(state, {
        filteredSkill: action.value,
    });
};

const setFilterDuration = (state, action) => {
    return updateObject(state, {
        filteredDuration: action.value,
    });
};

const setFilterkeywords = (state, action) => {
    return updateObject(state, {
        filteredKeywords: action.value,
    });
};

const setFilterFocusArea = (state, action) => {
    return updateObject(state, {
        filteredFocusArea: action.value,
    });
};

const setFilterEquipments = (state, action) => {
    return updateObject(state, {
        filteredEquipments: action.value,
    });
};

const setFilterIntensity = (state, action) => {
    return updateObject(state, {
        filteredIntensity: action.value,
    });
};

const setClassLanguage = (state, action) => {
    return updateObject(state, {
        filteredLanguage: action.value,
    });
};

const setFilteredSubCategory = (state, action) => {
    return updateObject(state, {
        filteredSubCategory: action.value,
    });
};

const setFilterSort = (state, action) => {
    return updateObject(state, {
        filterSortBy: action.value,
    });
};

const setFilterSearchText = (state, action) => {
    return updateObject(state, {
        filterSearchText: action.value,
    });
};

const setFilteredClassSearchData = (state, action) => {
    return updateObject(state, {
        filteredClassSearchData: action.value,
    });
};

const setFilteredTopKeywords = (state, action) => {
    return updateObject(state, {
        filteredTopKeywords: action.value,
    });
};

const setPageCounts = (state, action) => {
    return updateObject(state, {
        pageCount: action.value,
    });
};
const setPaginatedDatas = (state, action) => {
    return updateObject(state, {
        paginatedData: action.value,
    });
};
const setTopKeywOnDemand = (state, action) => {
    return updateObject(state, {
        topKeywordsOnDemand: action.value,
    });
};

const resetFilter = (state, action) => {
    return updateObject(state, {selectedFilters: [],
        filteredSkill: [],
        filteredDuration: '',
        filteredKeywords: [],
        filteredFocusArea: [],
        filteredEquipments: [],
        filteredIntensity: [],
        filteredLanguage: '',
        filteredSubCategory: '',
        filterSortBy: '',
        filterSearchText: '',
        filteredClassSearchData: [],
        filteredTopKeywords: [],
        loading: false,
        pageCount: 0,
        paginatedData: [],
        tempClassSearchDataOnDemand: [],
        });
};

const setClassesDataOnDemand = (state, action) => {
    return updateObject(state, {
        classesSearchDataOnDemand: action.value,
    });
};
const setTempClassesSearchdataOnDemand = (state, action) => {
    return updateObject(state, {
        tempClassSearchDataOnDemand: action.value,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_FILTERS_ONDEMAND:
            return setSelectedFilters(state, action);
        case actionTypes.SET_FILTER_KEYWORDS_ONDEMAND:
            return setFilterkeywords(state, action);
        case actionTypes.SET_FILTER_DURATION_ONDEMAND:
            return setFilterDuration(state, action);
        case actionTypes.SET_FILTER_SKILL_ONDEMAND:
            return setFilterSkill(state, action);
        case actionTypes.SET_FILTER_FOCUS_AREA_ONDEMAND:
            return setFilterFocusArea(state, action);
        case actionTypes.SET_FILTER_EQUIPMENTS_TYPES_ONDEMAND:
            return setFilterEquipments(state, action);
        case actionTypes.SET_FILTER_INTENSITY_ONDEMAND:
            return setFilterIntensity(state, action);
        case actionTypes.SET_CLASS_LANGUAGE_ONDEMAND:
            return setClassLanguage(state, action);
        case actionTypes.SET_FILTER_SORT_ONDEMAND:
            return setFilterSort(state, action);
        case actionTypes.SET_FILTER_SEARCH_TEXT_ONDEMAND:
            return setFilterSearchText(state, action);
        case actionTypes.SET_FILTERED_SUB_CATEGORY_ONDEMAND:
            return setFilteredSubCategory(state, action);
        case actionTypes.SET_FILTERED_CLASS_SEARCH_DATA_ONDEMAND:
            return setFilteredClassSearchData(state, action);
        case actionTypes.SET_FILTERED_TOP_KEYWORDS_ONDEMAND:
            return setFilteredTopKeywords(state, action);
        case actionTypes.SET_PAGE_COUNT_ONDEMAND:
            return setPageCounts(state, action);
        case actionTypes.SET_PAGINATED_DATA_ONDEMAND:
            return setPaginatedDatas(state, action);
        case actionTypes.SET_TOP_KEYWORDS_ONDEMAND:
            return setTopKeywOnDemand(state, action);
        case actionTypes.SET_CLASSES_SEARCH_DATA_ONDEMAND:
            return setClassesDataOnDemand(state, action);
        case actionTypes.SET_TEMP_CLASS_SEARCH_DATA_ONDEMAND:
            return setTempClassesSearchdataOnDemand(state, action);
        case actionTypes.RESET_FILTERS_ONDEMAND:
            return resetFilter(state, action);
        default:
            return state;
    }
};
export default reducer;
