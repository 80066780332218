import axios from 'axios'

export async function generateShortDynamicLink(
  apiKey,
  longDynamicLink
) {
  const response = await axios.post(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
    {
      longDynamicLink,
    },
  )

  return response
}
