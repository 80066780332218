import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
    selectedFilters: [],
    filteredSkill: [],
    filteredDuration: '',
    filteredKeywords: [],
    filteredFocusArea: [],
    filteredEquipments: [],
    filteredIntensity: [],
    filteredLanguage: '',
    filteredSubCategory: '',
    filterSortBy: '',
    filterSearchText: '',
    filteredClassSearchData: [],
    filteredTopKeywords: [],
    loading: false,
    pageCount: 0,
    paginatedData: [],
    classesSearchData: [],
    tempClassSearchData: [],
    topKeywords: [],
};

const setSelectedFilters = (state, action) => {
    return updateObject(state, {
        selectedFilters: action.value,
    });
};

const setFilterSkill = (state, action) => {
    return updateObject(state, {
        filteredSkill: action.value,
    });
};

const setFilterDuration = (state, action) => {
    return updateObject(state, {
        filteredDuration: action.value,
    });
};

const setFilterkeywords = (state, action) => {
    return updateObject(state, {
        filteredKeywords: action.value,
    });
};

const setFilterFocusArea = (state, action) => {
    return updateObject(state, {
        filteredFocusArea: action.value,
    });
};

const setFilterEquipments = (state, action) => {
    return updateObject(state, {
        filteredEquipments: action.value,
    });
};

const setFilterIntensity = (state, action) => {
    return updateObject(state, {
        filteredIntensity: action.value,
    });
};

const setClassLanguage = (state, action) => {
    return updateObject(state, {
        filteredLanguage: action.value,
    });
};

const setFilteredSubCategory = (state, action) => {
    return updateObject(state, {
        filteredSubCategory: action.value,
    });
};

const setFilterSort = (state, action) => {
    return updateObject(state, {
        filterSortBy: action.value,
    });
};

const setFilterSearchText = (state, action) => {
    return updateObject(state, {
        filterSearchText: action.value,
    });
};

const setFilteredClassSearchData = (state, action) => {
    return updateObject(state, {
        filteredClassSearchData: action.value,
    });
};

const setFilteredTopKeywords = (state, action) => {
    return updateObject(state, {
        filteredTopKeywords: action.value,
    });
};

const setPageCounts = (state, action) => {
    return updateObject(state, {
        pageCount: action.value,
    });
};
const setPaginatedDatas = (state, action) => {
    return updateObject(state, {
        paginatedData: action.value,
    });
};
const setTopKeywo = (state, action) => {
    return updateObject(state, {
        topKeywords: action.value,
    });
};
const setClassesData = (state, action) => {
    return updateObject(state, {
        classesSearchData: action.value,
    });
};
const setTempClassesSearchdata = (state, action) => {
    return updateObject(state, {
        tempClassSearchData: action.value,
    });
};

const resetFilter = (state, action) => {
    return updateObject(state, {
        selectedFilters: [],
        filteredSkill: [],
        filteredDuration: '',
        filteredKeywords: [],
        filteredFocusArea: [],
        filteredEquipments: [],
        filteredIntensity: [],
        filteredLanguage: '',
        filteredSubCategory: '',
        filterSortBy: '',
        filterSearchText: '',
        filteredClassSearchData: [],
        filteredTopKeywords: [],
        loading: false,
        pageCount: 0,
        paginatedData: [],
        tempClassSearchData: [],
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_FILTERS:
            return setSelectedFilters(state, action);
        case actionTypes.SET_FILTER_KEYWORDS:
            return setFilterkeywords(state, action);
        case actionTypes.SET_FILTER_DURATION:
            return setFilterDuration(state, action);
        case actionTypes.SET_FILTER_SKILL:
            return setFilterSkill(state, action);
        case actionTypes.SET_FILTER_FOCUS_AREA:
            return setFilterFocusArea(state, action);
        case actionTypes.SET_FILTER_EQUIPMENTS_TYPES:
            return setFilterEquipments(state, action);
        case actionTypes.SET_FILTER_INTENSITY:
            return setFilterIntensity(state, action);
        case actionTypes.SET_CLASS_LANGUAGE:
            return setClassLanguage(state, action);
        case actionTypes.SET_FILTER_SORT:
            return setFilterSort(state, action);
        case actionTypes.SET_FILTER_SEARCH_TEXT:
            return setFilterSearchText(state, action);
        case actionTypes.SET_FILTERED_SUB_CATEGORY:
            return setFilteredSubCategory(state, action);
        case actionTypes.SET_FILTERED_CLASS_SEARCH_DATA:
            return setFilteredClassSearchData(state, action);
        case actionTypes.SET_FILTERED_TOP_KEYWORDS:
            return setFilteredTopKeywords(state, action);
        case actionTypes.SET_PAGE_COUNT:
            return setPageCounts(state, action);
        case actionTypes.SET_PAGINATED_DATA:
            return setPaginatedDatas(state, action);
        case actionTypes.SET_TOP_KEYWORDS:
            return setTopKeywo(state, action);
        case actionTypes.SET_CLASSES_SEARCH_DATA:
            return setClassesData(state, action);
        case actionTypes.SET_TEMP_CLASS_SEARCH_DATA:
            return setTempClassesSearchdata(state, action);
        case actionTypes.RESET_FILTERS:
            return resetFilter(state, action);
        default:
            return state;
    }
};
export default reducer;
