import Localytics from "../Localytics"
import { connect } from "react-redux";
import {injectIntl} from 'react-intl';
import { setAppEventLoaded, setErrorEventLoaded, setTenantConfigEventLoaded, setUserEventLoaded } from "../store/actions/webPlayer";
const mapDispatchToProps = dispatch=>{
    return {
        eventLoaded: () => dispatch(setAppEventLoaded()),
        configEventLoaded: () => dispatch(setTenantConfigEventLoaded()),
        configUserLoaded: () => dispatch(setUserEventLoaded()),
        configErrorLoaded: () => dispatch(setErrorEventLoaded())
      };
}
const mapStateToProps = state => {
    return {
        webPlayerEvents: state.webPlayerEvents,
        userSubscription: state.subscription.userSubscripton,
        subscriptionProduct: state.subscription.subscriptionProduct,
        isAuthenticated: state.auth.isAuthenticated,
        userDetail: state.auth.userDetail,
        ClassDetails: state.workout.ClassDetails,
        PlayDuration: state.workout.PlayDuration,
        LSPlayDuration: state.workout.LSPlayDuration,
        subscriptionStatus: state.subscription.subscriptionStatus,
        isClassStarted: state.onDemand.isClassStarted,
        collectionItems: state.onDemand.onDemandCollection,
        clubConnectClicked: state.workout.clubConnectClicked,
        recordedClasses: state.onDemand.RecordedClasses,
        channelDetails: state.channels?.classes,
        categoryDetails: state.onDemand.categoryDetails,
        liveStreamClassDetails: state.workout.liveStreamClassDetails,
        tenantID: state.client?.data?.tenantID ?? '',
        isLiveStreamStarted: state.onDemand.liveStreamStarted
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Localytics));