import React, { useEffect, useState } from "react";
import { Dialog, Typography, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Translate } from "../../utils/Translate";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  ViberShareButton,
  LineShareButton,
  WeiboShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  ViberIcon,
  LineIcon,
  WeiboIcon,
  LinkedinIcon
} from "react-share";

import { displayNotification } from "../../store/actions/auth";
import IconClose from "../../CommonImages/icon-close";
const config = require('../../assets/config.json')
config.shareEnabled && require ("./ShareView.css");

const ShareView = (props) => {
  const dispatch = useDispatch();
  const [shareUrl, setShareUrl] = useState();
  const [title, setTitle] = useState();
  const [showViber, setshowViber] = useState(false);

  useEffect(() => {
    if (navigator?.platform) {
      if (
        !navigator.platform.includes("Win") &&
        !navigator.platform.includes("MacIntel")
      ) {
        setshowViber(true);
      }
    }
  }, [navigator]);

  useEffect(() => {
    if (props && props?.dynamicLinkDetails) {
      setShareUrl(props.dynamicLinkDetails.shareUrl);
      setTitle(props.dynamicLinkDetails.title);
    }
  }, [props]);

  const copyText = () => {
    copyTextToClipboard()
      .then(() => {
        let notificationTxt = Translate({ id: "homePage.LinkCopied" });
        dispatch(displayNotification(notificationTxt));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const copyTextToClipboard = async () => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(shareUrl);
    } else {
      return document.execCommand("copy", true, shareUrl);
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={props.closeDialog}
      id={"dialogContainer"}
      className="dialogContainer"
    >
      <div className="dialog-sub-container">
        <div className="share-view">
          <Typography className="shareTxt">
            {Translate({ id: "Actions.Share" })}
          </Typography>
          <IconClose cls="share-close makeTextClickable" onClick={props.closeDialog} />
        </div>
        <div className="share-container">
          <div className="icon-container">
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div className="icon-container">
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>

          <div className="icon-container">
            <WhatsappShareButton
              url={shareUrl}
              title={title}
              separator=":: "
              className="share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          {showViber && (
            <div className="icon-container">
              <ViberShareButton
                url={shareUrl}
                title={title}
                className="share-button"
              >
                <ViberIcon size={32} round />
              </ViberShareButton>
            </div>
          )}
          <div className="icon-container">
            <LineShareButton
              url={shareUrl}
              title={title}
              className="share-button"
            >
              <LineIcon size={32} round />
            </LineShareButton>
          </div>

          <div className="icon-container">
            <WeiboShareButton
              url={shareUrl}
              title={title}
              className="share-button"
            >
              <WeiboIcon size={32} round />
            </WeiboShareButton>
          </div>

          <div className="icon-container">
            <LinkedinShareButton
              url={shareUrl}
              title={title}
              className="share-button"
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>
        </div>
        <div className="shareurl-button">
          <Typography noWrap className="shareurl-txt">
            {shareUrl}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className="copy-btn"
            onClick={copyText}
          >
            <Typography className="copy-txt" variant="button">
              {Translate({ id: "Actions.Copy" })}
            </Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ShareView;
