/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import Routes from '../../components/Router/Routes'
import ClasslistViewFav from "../../hoc/ClasslistViewFavouriteContainer";
import ClasslistViewRecent from "../../hoc/ClasslistViewRecentContainer";
import Account from "../../hoc/AccountContainer";
import UpdateAccount from "../../hoc/UpdateAccountContainer";

var config = require("../../assets/config.json");

function ProfileWrapper(props){
  const [activeLink,setActiveLink] = useState('favourites')
  const pathname = props.history.location.pathname
  console.log(props.history.location.pathname)
  const isEmbededConfig = sessionStorage.getItem("embededConfig") !== null;
  const showAccount = () =>{
    return !isEmbededConfig
  }

  useEffect(() => {
    if(pathname) {
      setActiveLink(pathname.split('/').at(-1))
    }
  }, [pathname])
  return (
      <div className="page-container topProfileLink res-fav-acc m-b-xs-20 m-t-15">
            <Grid container spacing={0}>
        <Grid item className="topProfileLink-margin" >
              <a
                className={
                  activeLink === 'favourites'
                    ? `${config.name} dynamiclinks makeTextClickable` : "makeTextClickable"
                }
                onClick={() => {
                  props.history.push({ pathname: Routes.favClassRoute });

                }}
              >
                <Typography variant="body1">{Translate({ id: "ClassListViews.FavTitle" })}</Typography>
              </a>
            </Grid>
        <Grid item className="topProfileLink-margin">
              <a
                className={
                  activeLink === 'recent' ? `${config.name} dynamiclinks makeTextClickable` : "makeTextClickable"
                }
                onClick={() => {
                  props.history.push({ pathname: Routes.recentActivityRoute });
                }}
              >
                <Typography variant="body1">{Translate({ id: "ClassListViews.MyActivity" })}</Typography>
              </a>
            </Grid>
            { showAccount() ? (
                <Grid item>
                    <a
                      className={
                        (activeLink === 'account' || activeLink === "update" ) ? `${config.name} dynamiclinks makeTextClickable` : "makeTextClickable"
                      }
                      onClick={() => {
                        props.history.push({ pathname: "/user/account" });
                      }}
                    >
                      <Typography variant="body1">{Translate({ id: "UserAccount.Title" })}</Typography>
                    </a>
                </Grid>
            ) : null
            }
            
          </Grid>
          {activeLink === 'favourites' && <ClasslistViewFav />}
          {activeLink === 'recent' && <ClasslistViewRecent />}
          {activeLink === 'account' && <Account />}
          {activeLink === 'update' && <UpdateAccount  />}
      </div>
    );
  }

export default ProfileWrapper
