//eslint-disable
import * as actionTypes from "../../store/actions/actionTypes";
import basicApi from '../../api'
import thirdPartyApi from "./Api";
import * as APIConstants from "../ClubManager/Apiconstants";
import * as wexerApiConstants from '../../store/APIConstants';
import { GetUserDetail } from '../../store/actions/auth'

const config = require('../../assets/config.json')
//import { logoutUnsubscribedUsers, logout } from '../../containers/screens/login/action'

export const createThirdPartyUser = (data) => {
  return dispatch => {}
};

const produceUserDto = () => {
  return {
    firstName: "",
    lastName: "",
    response_type: "token",
    token: "",
    countryCode: "",
    consentRequest: [],
    email: "",
    tenantId: "",
    username: "",
    redirect_uri: "http://localhost:64116/",
    clientID: "",
    scope: "openid"
  };
};


export const AuthenticateWexerUser = (
  uname,
  exerpToken,
  email,
  exerpProfile
) => {
  return dispatch => {
    dispatch({ type: actionTypes.AUTH_START });

    var bodyFormData = new FormData();
    bodyFormData.append("client_id", process.env.REACT_APP_API_CLIENT_ID);
    bodyFormData.append("redirect_uri", process.env.REACT_APP_API_BASE_URL);
    bodyFormData.append("response_type", "token");
    bodyFormData.append("scope", "openid");
    bodyFormData.append("username", `${exerpProfile.first_name}`);
    bodyFormData.append("tenantId", "viva");
    bodyFormData.append("token", exerpToken);
    bodyFormData.append("countrycode", "au");
    bodyFormData.append("email", email);
    bodyFormData.append("skipConsent", "true");
    bodyFormData.append(
      "consentrequest",
      JSON.stringify([
        {
          ConsentTag: "f84c1942-5dda-4ef9-9417-1b6ec402d344",
          Action: 1
        }
      ])
    );
    console.log(bodyFormData)
    basicApi.setHeader("Content-Type", "multipart/form-data");
    //api.deleteHeader("Authorization");
    //api.deleteHeader("TenantId");
    basicApi
      .post(wexerApiConstants.thirdPartyUserOnWexerApi, bodyFormData)
      .then(response => {
        console.log(response)
        if (response.ok) {
          // if user has been created, show onboarding flow

          if (response.status === 201) {
            try {
              console.log(response.data.UserDetail.Code + "and" + response.data.UserDetail.UserId)
              localStorage.setItem('token', response.data.UserDetail.Code)
              localStorage.setItem('userId', response.data.UserDetail.UserId)
              dispatch({
                type: actionTypes.AUTH_SUCCESS,
                token: response.data.UserDetail.Code,
                userId: response.data.UserDetail.UserId,
                firstName: exerpProfile.first_name
              });

              //dispatch(getCustomFitProfileForExistingUser());
              //dispatch(getWorkoutListOnAppLaunch(false));
            } catch (error) {
              console.log(error);
            }
          }

          // if it is existing user no need to display onboarding flow.
          else if (response.status === 200) {
            try {
              console.log(response.data)
              localStorage.setItem('token', response.data.Code)
              localStorage.setItem('userId', response.data.UserId)
              dispatch({
                type: actionTypes.AUTH_SUCCESS,
                token: response.data.Code,
                userId: response.data.UserId,
                firstName: exerpProfile.first_name
              });
              dispatch(GetUserDetail());
              //dispatch({ type: actionTypes.ONBOARD_STATUS_CHANGED, status: true, data: null })


            } catch (error) {
              console.log(error);
            }
          }
        } else {
          if (response.status === 404) {
            dispatch({
              type: actionTypes.AUTH_FAIL,
              error: response.status
            });
          } else if (response.status === 500) {
            dispatch({
              type: actionTypes.AUTH_FAIL,
              error: response.status
            });
          } else {
            dispatch({
              type: actionTypes.AUTH_FAIL,
              error: response.originalError.message
            });
          }
        }
      });
  };
};

/**
 *  RR ||This method is used to get the user contracts from the third party// wil be reuse same on app.
 */

export const getThirdPartyUserSubscription = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_USER_SUBSCRIPTION_START
    });
    const UserAuthData = localStorage.getItem("thirdPartyAuth");

    if (UserAuthData != null) {
      thirdPartyApi(false)
        .get(APIConstants.getUserSubscription + UserAuthData)
        .then(response => {
          console.log(" getThirdPartyUserSubscription => ", response);
          if (response.status === 200) {

            if (response.data.length > 0) {
              if (response.data[0].status === "ACTIVE") {
                // dispatch(logout());
                dispatch({
                  type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                  userSubscripton: response.data,
                  status: true
                });
              }
              else {
                dispatch(logout());
                dispatch({
                  type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                  status: false
                });
              }
            }
            else {
              dispatch(logout());
              dispatch({
                type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                status: false
              });
            }
            //if (response.data.length > 0) {

            // } else {
            //   dispatch({
            //     type: actionTypes.CHECK_USER_SUBSCRIPTION_FAIL,
            //     userSubscripton: response.data
            //   });
            // }
          } else {
            console.log(`userSusbcription error ${response}`);
            dispatch(logout())
            dispatch({
              type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL
            });
          }
        })
        .catch(function (er) {
          console.log(`userSusbcription catch ${er}`);
        });
    }
  };
};

export const logout = () => {
  return dispatch => {
    if (config.canUnsubcribedUsersLogin == false) {

      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      dispatch({
        type: actionTypes.AUTH_FAIL,
        status: false
      });

    }
  }
}
export const AuthenticateThirdPartyUser = (uname, pwd) => {
  return dispatch => {
    dispatch({ type: actionTypes.AUTH_START });
    thirdPartyApi()
      .get(APIConstants.LoginAPI, {
        e: uname,
        p: pwd
      })
      .then(

        response => {

          console.log(response)
          if (response.status == 200 && response.data != null && response.data.length > 0) {
            if (response.data[0].status == "ACTIVE") {
              localStorage.setItem("thirdPartyAuth", response.data[0].token)
              dispatch({
                type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                userSubscripton: response.data
              });
              try {
                dispatch({
                  type: actionTypes.THIRD_PARTY_AUTH_SUCCESS,
                  token: response.data[0].token,
                  userId: response.data[0].username,
                  exerp: response.data.user
                });
                let userDto = produceUserDto();
                userDto.clientID = "1234123412341234";
                userDto.skipConsent = "true";
                userDto.token = response.data[0].token;
                userDto.username = uname;
                userDto.email = uname;
                userDto.firstName = response.data[0].firstname;
                userDto.lastName = response.data[0].surname;
                userDto.countryCode = "au";
                userDto.response_type = "token";
                userDto.tenantId = "viva"; //config.name;


                let userData = {
                  first_name: response.data[0].firstname,
                  last_name: response.data[0].surname,
                  email: response.data[0].username,
                  id: ""
                }
                dispatch(
                  AuthenticateWexerUser(
                    userDto.username,
                    userDto.token,
                    userDto.email,
                    userData
                  )
                );

              } catch (error) {
                console.log(error);
              }
            } else if (response.data[0].error.toLowerCase() == "Invalid Account.".toLowerCase()) {

              dispatch({
                type: actionTypes.AUTH_FAIL

              });
              dispatch({
                type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                status: false
              });
            } else if (response.data[0].error.toLowerCase() == "No valid account.".toLowerCase()) {
              console.log("subscription failed")
              dispatch({
                type: actionTypes.AUTH_FAIL,
                error: "Invalid username or password"
              });
            }
            else {
              console.log("subscription failed")
              dispatch({
                type: actionTypes.AUTH_FAIL,
                error: ""
              });
              dispatch({
                type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
                status: false
              });
            }

            //dispatch(GetConsentDetail());
            //dispatch(GetUserConsent());
          } else {
            if (response.status === 404) {
              dispatch({
                type: actionTypes.AUTH_FAIL,
                error: response.status
              });
            } else if (response.status === 500) {
              dispatch({
                type: actionTypes.AUTH_FAIL,
                error: response.status
              });
            } else {
              dispatch({
                type: actionTypes.AUTH_FAIL,
                error: response.originalError
              });
            }
          }
        },
        err => {
          console.log(err);
          dispatch({
            type: actionTypes.AUTH_FAIL,
            error: err.originalError
          });
        }
      );
  };
};


export const thirdPartyResetPassword = emailId => {
  return dispatch => {
    dispatch({ type: actionTypes.RESET_PASSWORD_START });
    var body = {
      username: emailId
    };

    thirdPartyApi()
      .post(APIConstants.ResetPassword + emailId)
      .then(response => {
        console.log(response)
        if (response.ok) {
          if (response.status == 200) {
            if (response.data.length > 0 && response.data[0].status == "sent") {
              dispatch({ type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_SUCCESS });
              return response.data;
            }
            else {
              dispatch({ type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL, errorMsg: "No account could be found associated with this email address." });
              console.log(response);
            }

          } else {
            dispatch({ type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL });
            console.log(response);
          }
        } else {
          dispatch({
            type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL
          });
        }
      });
  };
};

