/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React from "react";
import { Translate } from "../../utils/Translate";
import { Avatar, Grid, Typography } from '@material-ui/core';
import AccountButtonDotsMobile from "./AccountButtonDotsMobile";
import Routes from '../../components/Router/Routes';
const config = require("../config.json")

class LoggedInTexts extends React.Component {
  componentDidMount() { }
  render() {
    return (
      <React.Fragment>
        <div className="loginButtonHolder">
          <Grid container justify="flex-end" alignItems="center">
            {(config.showLiveEvent || config.ShowLiveConnect) &&
              <Grid item>
                <a
                  className={
                    this.props.history.location.pathname == Routes.connectClasses || 
                    (this.props.history.location.pathname.indexOf("live") > -1) || 
                    (this.props.history.location.pathname.indexOf("connect") > -1)
                      ? `headerLinks-fitness dynamiclinks`
                      : "headerLinks-fitness"
                  }
                  onClick={() => {
                    config.showLiveEvent ?
                      this.props.history.push({ pathname: Routes.connectLiveSchedule }) :
                      this.props.history.push({ pathname: Routes.connectOndemandClasses })

                  }}
                >
                  <Typography variant="button">{Translate({ id: "navigation.ConnectTopNav" })}</Typography>
                </a>
              </Grid>}
            <Grid item>
              <a
                className={
                  this.props.history.location.pathname == Routes.classListViewRoute ||
                    (window.location.href.indexOf("categories") > -1)
                    ? `headerLinks-fitness dynamiclinks`
                    : "headerLinks-fitness"
                }
                onClick={() => {
                  this.props.history.push({ pathname: Routes.classListViewRoute });
                }}
              >
                <Typography variant="button">{Translate({ id: "navigation.ClassesTopNav" })}</Typography>
              </a>
            </Grid>
            {config.showChannels && <Grid item>
              <a
                className={
                  this.props.history.location.pathname == "/channels" || (window.location.href.indexOf("channels") > -1)
                    ? `headerLinks-fitness dynamiclinks`
                    : "headerLinks-fitness"
                }
                onClick={() => {
                  this.props.history.push({ pathname: "/channels" });
                }}
              >
                <Typography variant="button">{Translate({ id: "navigation.ChannelsTopNav" })}</Typography>
              </a>
            </Grid>}
            <Grid item>
              <a
                className={
                  this.props.history.location.pathname == Routes.collections || (window.location.href.indexOf("collection") > -1)
                    ? `headerLinks-fitness dynamiclinks`
                    : "headerLinks-fitness"
                }
                style={{
                  marginRight: "10px"
                }}
                onClick={() => {
                  this.props.history.push({ pathname: Routes.collections });
                }}
              >
                <Typography variant="button">{Translate({ id: "navigation.CollectionsTopNav" })}</Typography>
              </a>
            </Grid>
            <Grid item>
              <Avatar style={{ cursor: "pointer" }} alt="Travis Howard"
                // src={avatarImg}
                onClick={() => {
                  this.props.history.push({ pathname: Routes.favClassRoute });
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="loginButtonHolderMobile pull-right">
          <Grid container justify="flex-end" alignItems="center">
            <Grid item>
              <Avatar alt="Travis Howard"
                onClick={() => {
                  this.props.history.push({ pathname: Routes.favClassRoute });
                }}
              />
            </Grid>
            <AccountButtonDotsMobile></AccountButtonDotsMobile>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
export default LoggedInTexts;
