import React, { Component } from "react";
import Footer from "../../components/Footer/Footer";
// import { getUserActiveSubscription } from "../../store/selectors/subscription";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Appbar from "../../components/AppBarComponents/Appbar";
import { FirebaseApp } from "../../FirebaseApp";



var config = require("../../assets/config.json");
function Alert(props) {
  return <MuiAlert  variant="filled" severity={props.NotificationType === "error" ? "info" : "success"} {...props} />;
}
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifyUser: false,
      NotificationText: "",
    };
    this.firebaseApp = new FirebaseApp();
    this.firebaseApp.defaultTrace();
  }
  logoutUser = () => {
    this.props.onLogout();
    window.location.href = "/";
  };

  state = {
    // isActiveFreeTrial: false,
    // isExpiredFreeTrial: false,
    // otherSubs: false,
  };


  resetSubscriptionStatus = () => {
    this.props.resetSubscriptionStatus();
    if (config.canUnsubcribedUsersLogin === false) {
      this.logoutUser();
    }
    if (config.resetPasswordRedirectURL) {
      window.open(config.resetPasswordRedirectURL, '_blank');
    }
    if (config.UpgradeRedirect) {
      window.open(config.UpgradeRedirect, '_blank');
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.notifyUser !== this.props.notifyUser) {
      this.setState({
        notifyUser: this.props.notifyUser,
        NotificationText: this.props.NotificationText,
      });

    }
  }


  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.CloseNotification()
    this.setState({ "notifyUser": false });
  };
  getClassName = () =>{
    if(config?.ssoLogin === true){
      return "mainHolder mainHolder-oauth"
    }
    else if(config?.isThirdPartyEnabled=== true && config?.msalSsoSupport === true){
      return "mainHolder mainHolder-sso-support"
    }
    return "mainHolder";
  }
  render() {
    // snackbar alert
    return (<div className="th-black">
      <div>
        {this.state.notifyUser ? (
          //  {/* <Notification NotificationText={this.state.NotificationText} /> */}
          <Snackbar open={true} autoHideDuration={3000} onClose={this.handleClose}> 
            <Alert NotificationType={this.props.NotificationType} style={{
              borderRadius: "20px",
              backgroundColor: config.secondaryColor,
              marginBottom: "40px"
            }}>
              {this.state.NotificationText}
            </Alert>
          </Snackbar>
        ) : (
            <div />
          )
        }
        {

          <Appbar
            history={this.props.history}
            isAuthenticated={this.props.isAuthenticated}
          />

        }

        <main
          style={{
            // width: window.screen.width < 1368 ? "100%" : "1210px",
            margin: "auto",
           
          }}
         className= {this.getClassName()}
        >
          {/* {config.canUnsubcribedUsersLogin == false && this.props.isSubscriptionActive == false ?
            <CustomDialog
              open={true}
              title={Translate({ id: "Subscription.UpgradeMembership" })}
              heading={Translate({ id: "Subscription.UpgradeMessage" })}
            >
              <div className="text-center padding16">
                <Button size="small" type="submit" color="secondary"
                  className="button btn-default" variant="contained"
                  onClick={this.resetSubscriptionStatus}
                  style={{ marginTop: "60px" }}
                >
                  <Typography variant="h5">
                    {Translate({ id: "Subscription.Ok" })}
                  </Typography>
                </Button>
              </div>
            </CustomDialog>
            : null} */}
          {this.props.children}
        </main>

      </div >

      {
        ((config.embeddedConfig && config.embeddedConfig.showFooter) || 
        !config.embeddedConfig) ?
        < div className="footerText">
          <Footer clientName={localStorage.getItem("ClientDisplayName")} />
        </div>:< div className="ewfooterSpace"/>
      }
      </div >
    );
  }
}

export default Layout;