import { useDispatch, useSelector } from "react-redux";
import { setselectedFilters, setKeywords, setDuration, setSkillLevel, setFocusArea, setEquipmentsTypes, setIntensityLevel, setClassLanguage, setCategories, setSort, setFilterSearchText, resetFilters } from '../store/actions/filter';
import { setselectedFiltersOnDemand, setKeywordsOnDemand, setDurationOnDemand, setSkillLevelOnDemand, setFocusAreaOnDemand, setEquipmentsTypesOnDemand, setIntensityLevelOnDemand, setClassLanguageOnDemand, setCategoriesOnDemand, setSortOnDemand, setFilterSearchTextOnDemand, resetFiltersOnDemand } from '../store/actions/filterOnDemand';
import { getTranslatedFile } from "../utils/utilityfunctions";

const useFilterDrawer = () => {
    const dispatch = useDispatch();
    const useFilterDrawerState = (props) => ({
        loading: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.loading : state.filter.loading),
        selectedFilters: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.selectedFilters : state.filter.selectedFilters),
        filteredSkill: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.filteredSkill : state.filter.filteredSkill),
        filteredDuration: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.filteredDuration : state.filter.filteredDuration),
        filteredKeywords: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.filteredKeywords : state.filter.filteredKeywords),
        filteredSubCategory: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.filteredSubCategory : state.filter.filteredSubCategory),
        filteredFocusArea: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.filteredFocusArea : state.filter.filteredFocusArea),
        filteredEquipments: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.filteredEquipments : state.filter.filteredEquipments),
        filterSortBy: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.filterSortBy : state.filter.filterSortBy),
        filterSearchText: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.filterSearchText : state.filter.filterSearchText),
        filteredIntensity: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.filteredIntensity : state.filter.filteredIntensity),
        filteredLanguage: useSelector(state => props.showFLLOnDemand ? state.filterOnDemand.filteredLanguage : state.filter.filteredLanguage)
    })
    const useFilterDrawerDispatch = () => ({
        setOnDemandSelectedFilter: (tempFilters) => {
            dispatch(setselectedFiltersOnDemand(tempFilters))
        },
        setSelectedFilters: (tempFilters) => {
            dispatch(setselectedFilters(tempFilters))
        },
        setOnDemandKeywords: (filterValue) => {
            dispatch(setKeywordsOnDemand(filterValue))
        },
        setKeywords: (filterValue) => {
            dispatch(setKeywords(filterValue));
        },
        setOnDemandDuration: (filterValue) => {
            dispatch(setDurationOnDemand(filterValue))
        },
        setDuration: (filterValue) => {
            dispatch(setDuration(filterValue));
        },
        setOnDemandSkillLevel: (filterValue) => {
            dispatch(setSkillLevelOnDemand(filterValue))
        },
        setSkillLevel: (filterValue) => {
            dispatch(setSkillLevel(filterValue));
        },
        setOnDemandFocusArea: (filterValue, filterTag) => {
            dispatch(setFocusAreaOnDemand([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]))
        },
        setFocusArea: (filterValue, filterTag) => {
            dispatch(setFocusArea([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]));
        },
        setOnDemandEquipmentsTypes: (filterValue, filterTag) => {
            dispatch(setEquipmentsTypesOnDemand([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]))
        },
        setEquipmentsTypes: (filterValue, filterTag) => {
            dispatch(setEquipmentsTypes([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]));
        },
        setOnDemandIntensityLevel: (filterValue) => {
            dispatch(setIntensityLevelOnDemand(filterValue))
        },
        setIntensityLevel: (filterValue) => {
            dispatch(setIntensityLevel(filterValue));
        },
        setOnDemandClassLanguage: (filterValue, filterTag) => {
            dispatch(setClassLanguageOnDemand([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]))
        },
        setClassLanguage: (filterValue, filterTag) => {
            dispatch(setClassLanguage([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]));
        },
        setOnDemandCategories: (filterValue) => {
            dispatch(setCategoriesOnDemand(filterValue))
        },
        setCategories: (filterValue) => {
            dispatch(setCategories(filterValue));
        },
        setOnDemandSort: (filterValue) => {
            dispatch(setSortOnDemand(filterValue))
        },
        setSort: (filterValue) => {
            dispatch(setSort(filterValue));
        },
        setFilterSearchText: (searchText) => {
            dispatch(setFilterSearchText(searchText))
        },
        setOnDemandFilterSearchText: (searchText) => {
            dispatch(setFilterSearchTextOnDemand(searchText))
        },
        resetOnDemandFilters: () => {
            dispatch(resetFiltersOnDemand());
        },
        resetFilters: () => {
            dispatch(resetFilters());
        },
    })


    const initialState = {
        clearSearch : "none",
        skillValue  : [],
        intensityValue : [],
        levelText : "",
        level : "",
        intensityText : "",
        intensity:"",
        searchText : "" ,
        keyWordPressed:false,
        expanded: false,
        isIOS : false,
        isAndroid: false,
        translatedFile:getTranslatedFile()
    };

    const useFilterDrawerAction = {
        UPDATE_FILTERDRAWER: "UPDATE_FILTERDRAWER",
    };

    const useFilterDrawerReducer = (state, action) => {
        const { type, payload } = action;
        switch (type) {
           
            case useFilterDrawerAction.UPDATE_FILTERDRAWER:
                return { ...state, ...payload };

            default:
                return state
        }
    };

    const setReducerData = (filterDrawerDispatch,payload)=>{
        filterDrawerDispatch({
            type:useFilterDrawerAction.UPDATE_FILTERDRAWER,
            payload:payload
        })
    }




    return { 
        useFilterDrawerState,
        useFilterDrawerDispatch,
        filterDrawerReducer : {useFilterDrawerReducer ,useFilterDrawerAction , initialState, setReducerData }
    }
}
export { useFilterDrawer }