

import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function Mq(i=250) {
    return useMediaQuery(`@media (min-width: ${i}px)`);
}
  export function IsMiniMobile() {
      const theme = useTheme();
      return useMediaQuery(theme.breakpoints.up('xs'));
  }
  export function IsMobile() {
    return useMediaQuery(`@media (max-width: 767px)`);
  }
  export function IsTab() {
    return useMediaQuery(`@media (min-width: 768px)`);
    }
  export function IsDesktop() {
      return useMediaQuery(`@media (min-width: 1360px)`);
  }
export function IsProjector() {
    return useMediaQuery(`@media (min-width: 2000px)`);
}