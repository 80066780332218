import React from "react";
import "./Themes/theme.scss";
import Routes from "../../components/Router/Routes"
import { useNavigate } from "react-router-dom";
import {getCardsDate, getTimetoStart} from "../../utils/LivestreamTime"
import moment from "moment";

const ResCard = (props) => {
 const {collection,cardType,list}= props
 //let history = useNavigate()
 const collectionType = collection?.collectionType
 const items = collection?.collectionItems
//  if(collectionType === 'featured') {
//     items.length = items.length > 3 ? 3 : items.length
// }
collection && console.log(collection)
const handleClick =(index,tag,data) =>{
    if(!!props.takeUserToClassDetail) return props.takeUserToClassDetail(index)
    else if (collectionType === "featured"){
       const item = { 
        keywords:data.keywords,
        isSearchPageCard:false,
        creationDate:data.scheduleDate,
        trailerLinkWeb:data.trailerLinkWeb,
        trailerLinkMobile:data.trailerLinkMobile,
        isSliderWorkout:true,
        showFav: true ? true : false,
        thumbnail:data.imageLink,
        equipmentTypes:data.equipmentTypes,
        equipmentNames:data.equipmentNames,
        isFav:data.favourite,
        title:data.className,
        duration:data.durationSecond
          ? Math.ceil(data.durationSecond / 60)
          : 55,
        description:data.classDescription,
        show:true,
        calories:data.calorieBurn,
        id:data.tag,
        classCategory:data.classCategory,
        key:collection?.collectionName + index,
        level:data.level,
        collectionName: collection?.collectionName}
        props.history.push({
            pathname: Routes.onDemandClassPlayerRoute + tag,
            state: { selectedItem: item, prevPath: Routes.onDemandClassPlayerRoute },
          });
    }
    else if (collectionType === "channel"){
        props.history.push({
            pathname: Routes.onDemandClassPlayerRoute + data.title[0].tag,
            state: { selectedItem: data,from:'CHANNEL' },
          });
    }
}
const cotdDate = (date)=>{
    try{
    return moment(date).format("dddd DD MMMM") 
    } catch(e){
    return moment().format("dddd DD MMMM")
    }
 }
    return (
        !items ? <h1>Loading</h1> : 
            <div className={`list ${list}`}>
                {collectionType !== "channel" && items.map( (item,index) => <div>
                    <div className={` ${cardType}`} onClick={()=>handleClick(index,item.tag,item)}><div className={`cardPos comman`} > 
                        <div class={`imageHolder ${cardType !== 'cotd-card' ? collectionType !== 'featured' ? "full-overlay":'' :"cotd-overlay"}`} style={{backgroundImage:`url(${item.imageLink ?? item.title[0].imageLink ?? item.pictures.sizes[0].link})`}}  >
                            <div class="contentTop">
                                <div class="timeholder">
                               {collectionType ==="live" && <span color="white" class="live-title m-b-8">{(item.className?? item.title[0].className??item.title).substring(0,20)}</span> }
                               {collectionType !=="featured" && <span color="white" class={`classname ${cardType === 'cotd-card ' ? "cotd-date":"m-b-5"}`}>{cotdDate(item.schedule?.availableDate ?? item.displayDate)}</span> }
                               {collectionType ==="live" &&  <span color="white" class="live-time">{getTimetoStart(item.schedule?.availableDate )}</span>}
                
                                </div>
                            </div> 
                        </div> 
                    </div>
                    </div>
                {collectionType !=="live" && <div class="card-footer">
                    <div color="white" style={{color:'black'}} class="time">{(item.className?? item.title[0].className??item.title)}</div>
                        <div className="d-flex">
                            <span color="white" style={{color:'grey'}} spacing="xl" class="font-caption discIcon">{(item.durationSecond ?? item.title[0].durationSecond)
                                            ? Math.ceil((item.durationSecond ?? item.title[0].durationSecond) / 60)
                                            : 55} mins</span> 
                            <span color="white" spacing="s" style={{color:'grey'}} class="font-caption discIcon"> {getCardsDate(item.schedule?.availableDate ?? item.displayDate)}</span>
                            <span color="white" spacing="s" style={{color:'grey'}} class="font-caption"> { item.level}</span></div>
                        </div>} 
                </div>
                )}  
                {collectionType === "channel" && items.map( (item,index) => <div className="comman" >
                     <div className={`cardPos ${cardType}`} onClick={()=>handleClick(index,item.tag,item)}> 
                    <div class="imageHolder" style={{backgroundImage:`url(${item.title[0].imageLink ?? item.pictures.sizes[0].link})`}}  >
                        <div class="class-card__CardContentTop contentTop">
                            <div class="timeholder"> 
                                <span color="white" class="classname">{item.displayName}</span>
                            </div>
                        </div>
                               
                        <div class="class-card__CardContentBottom-sc-8519zh-3 bottomHolder ">
                            <img src={item.profileImageUrl} className="channelImg" alt="channelImage" />
                            <span color="white" spacing="s" class="font-caption"> { item.description}</span>
                        </div>
                    </div> 
                </div>
                </div>
                )}  
        </div>
    )}
export default ResCard