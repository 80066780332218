import React from "react";
import WorkoutCompleted from '../PlayerAndCorousel/views/Player/WorkOutCompleted'


function ClassCompleted(props) {
    const { history } = props;
    return (
        <React.Fragment>
                <WorkoutCompleted class="workOutCompleted p-lr-xs-15" elapsedTime={props.duration} workOutCompleteHandler={() => history.goBack()} />
        </React.Fragment>

    );
}

export default ClassCompleted;  