const customDialogStyle = {
  paper1: {
    width: "100%",
    maxWidth: 800,
    textAlign: "center",
    maxHeight: 496,
    height: "auto",
    minHeight: 320,
    paddingBottom: 136,
    borderRadius:"10px"
  },
  paper2: {
    width: "100%",
    maxWidth: 800,
    textAlign: "center",
    maxHeight: "475px",
    height: "auto",
    paddingTop: "20px",
    paddingBottom: "20px",
    borderRadius:"10px",
    margin:'8px',
    '@media (min-width:768px)':{
      paddingTop: "55px",
      paddingBottom: "52px"
    },
    '@media (min-width:1360px)':{
      paddingTop: "52px",
    },
    '@media (min-width:2000px)':{
      paddingTop: "45px",
    },
  },
  
  paper3: {
    width: "100%",
    maxWidth: 1840,
    textAlign: "center",
    maxHeight: "80%",
    height: "auto",
    minHeight: 320,
    paddingBottom: 56,
    borderRadius:"10px",
    '@media (max-width:719px)':{
      //paddingTop: "30px",
      paddingBottom: "10px"
    },
  }
};

export default customDialogStyle;