import { useDispatch } from "react-redux";
import {resetFetchClubLiveConnect } from "../store/actions/ondemand"

const useWrapperList = () => {
    const dispatch = useDispatch();
    const useWrapperListDispatch = () => ({
        resetFetchClubLiveConnect: ()=> {
            dispatch(resetFetchClubLiveConnect())
        },
    })
    return {useWrapperListDispatch}
}
export {useWrapperList}