import { useDispatch } from "react-redux";
import { FetchClubLiveConnect } from '../store/actions/ondemand';
const useClubList = () => {
    const dispatch = useDispatch();
    const useClubListDispatch = () => ({
        fetchClubLiveConnect: (apiOnDemandPageCount)=> {
            dispatch(FetchClubLiveConnect(apiOnDemandPageCount))
        },
    })
    return {useClubListDispatch}
}
export {useClubList}