import moment from "moment";


export const getPayload = (selectedClass, trackingResult, time) => {
    const payload = {
        classCommitmentTag: null,
        primaryGoal: 0,
        secondaryGoal: 0,
        skillLevel: 0,
        durationSeconds: time,
        mood: null,
        completedDate: moment().format(),
        tag: 0,
        intensityLevel: 6,
        workoutName: selectedClass.title,
        userWorkoutTag:  0,
        isTimed: false,
        calorieBurn: 0, // int req
        exercises: null,
        durationMinutes: Math.round(time / 60), // int req
        trackingResult: trackingResult,
        imageUrl: selectedClass.thumbnail ? selectedClass.thumbnail : "",
        userId: localStorage.getItem("userId"),
        trailerLinkWeb: selectedClass.trailerLinkWeb ? selectedClass.trailerLinkWeb :"" ,
        workoutDescription: selectedClass.description ? selectedClass.description : "",
        creationDate: selectedClass.creationDate,
        keywords: null,
        classCategories:  " ",
        refTemplateId: selectedClass.id
    };
    const returnPaylod = payload;
    return returnPaylod;
}