import { useDispatch, useSelector } from "react-redux";
import { FetchLSConnectHome } from "../store/actions/ondemand";

const useDefaultClassesPage = () => {
    const dispatch = useDispatch();
    const useDefaultClassesPageState = () => ({
        LSHomeClasses :useSelector(state => state.onDemand.LSHomeClasses),
        liveConnectLoading : useSelector(state => state.onDemand.liveConnectLoading),
        metadata : useSelector(state => state.onDemand.Metadata),
        loading : useSelector(state => state.onDemand.loading)
    })
    const useDefaultClassesPageDispatch = () => ({
        fetchLSConnectHome : () => {
            dispatch(FetchLSConnectHome())
        }
    })
    return {useDefaultClassesPageState, useDefaultClassesPageDispatch}
}
export {useDefaultClassesPage}