import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SpinnerConstants} from "../../utils/constants"

class Spinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size : props.size || SpinnerConstants.SIZE,
      thickness : props.thickness || SpinnerConstants.THICKNESS
    }
  }
  render() {
    return (
        <div style={{textAlign:"center"}} className="classes-spinner">
              {!this.props.areCards && (
                <CircularProgress
                  label="lable"
                  size={this.state.size}
                  // className={this.props.classes.progress}
                  thickness={this.state.thickness}
                  color="secondary"
                />
              )}
        </div>
    );
  }
}

export default Spinner;
