import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Typography, Fade } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import Button from "@material-ui/core/Button";
import moment from "moment";
import ProfileWrapper from "./ProfileWrapper";
import CustomDialog from "../../components/Modal/Modal";
import CustomDialogFAC from "../../components/Modal/ModalFac";
import Spinner from "../../components/spinner/spinner";
const config = require("../../assets/config.json")

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      isFACDialogOpen: false,
      loading: true,
      couponCode: "",
      iscouponCodeFilled: false
    }
  }

  handleDialog = () => {
    this.setState({
      isDialogOpen: true,
    })
  }

   handleFACDialogClickEvent = (event) => {
    const dialog = document.getElementById('account-Fac')
    if (!(dialog.contains(event.target))) {
      console.log('clicked inside');
      this.handleFACDialogClose()
    } else {
      console.log('clicked outside');
    }
  }
  handleFACDialogOpen = () => {
    this.setState({
      isFACDialogOpen: true,
    })
    setTimeout(() => {
      document.body.addEventListener('click', 
        this.handleFACDialogClickEvent
      );
    }, 100);
  }
  handleFACDialogClose = () => {
    document.body.removeEventListener('click', this.handleFACDialogClickEvent)
    this.setState({
      isFACDialogOpen: false,
    })
  }
  /**
   * 06th June 2020
   * Nikhil Gupta
   * CF-7973
   * Enter Free access code from account page
   */
  renewSubscription = e => {
    e.preventDefault();
    this.props.onRenewSubscription(this.state.couponCode)
  }

  handleDialogClose = () => {
    this.setState(
      {
        isDialogOpen: false,
      }
    )
  }

  logoutUser = () => {
    this.props.onLogout();
    window.location.href = "/";
  };

  componentDidMount() {
    // if (this.props.userDetail === null) {
    this.props.loadUserDetail();
    !config.isThirdPartyEnabled && this.props.loadUserSubscriptionDetails();
    //!config.isThirdPartyEnabled && this.props.loadUserSubscription();
    // }
    !config.isThirdPartyEnabled && this.props.loadSubscriptionProducts();
    if (!this.props.isAuthenticated) {
      this.props.saveDeeplinkPayload(this.props.location.pathname)
      this.props.history.push({ pathname: "/signin", redirect: true });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.state.couponCode.length !== 0) {
      if (this.state.iscouponCodeFilled === false) {
          this.setState({
              iscouponCodeFilled: true,
          })
      }
    }
    else {
      if (this.state.iscouponCodeFilled === true) {
          this.setState({ iscouponCodeFilled: false })
      }
    }
    if (prevProps.userDetail !== this.props.userDetail) {
      this.setState({ loading: false})
    }
  }

  CancelUserSubscription = () => {
    this.props.cancelUserSubscription(
      this.props.userActiveSubscription.subscription.subscriptionTag
    );
  };
  //not working as if now
  ReactivateUserSubscription = () => {
    this.props.reactivateUserSubscription(
      this.props.userActiveSubscription.subscription.subscriptionTag,
      this.props.userActiveSubscription.plan.tag,
      this.props.userActiveSubscription.plan.productId
    );
  };

  changeEmailClickHandler = () => {
    this.props.history.push({
      pathname: "/user/update",
      state: { updateItem: "email" },
    });
  };

  editProfileClickHandler = () => {
    this.props.history.push({
      pathname: "/user/update",
      state: { updateItem: "name" },
    });
  };

  CancelUserSubscription = () => {
    this.props.cancelUserSubscription(
      this.props.userSubscription.subscriptionTag
    );
  };


  render() {
    let subscriptionStatus = ''
    let subscriptionDetails = this.props.subscriptionDetails
    let showCancelbutton = false
    let showReActivatebutton = false
    const en_moment = (date)=>{
      const enMoment = moment(date)
      enMoment.locale('en') 
      return enMoment
    }

  const date = (date)=>{
    try{
    return (date ? moment(date).format("DD.MM.YYYY") : "-") 
    } catch(e){
    return "-"
    }
 }

    const showSubscriptionDetails = ()=> {
      return (<> <div className="subscription-detail">
      <Typography variant="h3" className="align-left text-lightgray">
      {Translate({
          id: "Subscription.BillingFrequency",
        })}
      </Typography>
      <Typography variant="h3" className="align-left text-black">
        {subscriptionDetails?.billingFrequency ? Translate({ id:`Subscription.${subscriptionDetails?.billingFrequency}`}) : "-"}
      </Typography></div>
      <div className="subscription-detail">
      <Typography variant="h3" className="align-left text-lightgray">
      {Translate({
          id: "Subscription.SubscriptionPrice",
        })}
      </Typography>
      <Typography variant="h3" className="align-left text-black">
      {subscriptionDetails?.subscriptionPrice ?   `${subscriptionDetails?.currency.toUpperCase()} ${subscriptionDetails?.subscriptionPrice}` : "-"}
      </Typography></div>
      <div className="subscription-detail">
      <Typography variant="h3" className="align-left text-lightgray">
      {Translate({
          id: "Subscription.DiscountCode",
        })}
      </Typography>
      <Typography variant="h3" className="align-left text-black">
        {subscriptionDetails?.percentageOff > 0 ? `${subscriptionDetails?.percentageOff }%` : "-"}
      </Typography></div>
      <div className="subscription-detail">
      <Typography variant="h3" className="align-left text-lightgray">
      {Translate({
          id: "Subscription.DiscountExpiry",
        })}
      </Typography>
      <Typography variant="h3" className="align-left text-black">
        {date(subscriptionDetails?.discountExpiry)}
      </Typography></div>
      <div className="subscription-detail">
      <Typography variant="h3" className="align-left text-lightgray">
      {Translate({
          id: "Subscription.LastInvoiceAmount",
        })}
      </Typography>
      <Typography variant="h3" className="align-left text-black">
      { `${subscriptionDetails?.currency?.toUpperCase() ?? ''} ${subscriptionDetails?.lastInvoiceAmount ?? "-" }`}
      </Typography></div>
      <div className="subscription-detail">
      <Typography variant="h3" className="align-left text-lightgray">
      {Translate({
          id: "Subscription.NextInvoiceDate",
        })}
      </Typography>
      <Typography variant="h3" className="align-left text-black">
        {date(subscriptionDetails?.nextInvoiceDate)}
      </Typography></div>
      <div className="subscription-detail">
      <Typography variant="h3" className="align-left text-lightgray">
      {Translate({
          id: "Subscription.NextInvoiceAmount",
        })}
      </Typography>
      <Typography variant="h3" className="align-left text-black">
       {`${subscriptionDetails?.currency?.toUpperCase() ?? ""} ${subscriptionDetails?.nextInvoiceAmount ?? "-"}` }
      </Typography> </div></>)
       }
    
    if (this.props.userSubscription != null) {

      if (this.props.userSubscription.status === "trialing") {
        if (this.props.userSubscription.canceledAt != null) {
          if (this.props.userSubscription.cancelAtPeriodEnd === true) {
            subscriptionStatus= <Typography variant="h3" className="align-left text-lightgray">{Translate({
              id: "Subscription.CanceledSubscriptionMessage",
              values: {
                subEndDate: moment(
                  this.props.userSubscription.currentPeriodEnd
                ).format("Do MMM YYYY"),
              },
            })}</Typography>
          }
        }
        else {
          showCancelbutton = true
          subscriptionStatus= <Typography variant="h3" className="align-left text-lightgray">{Translate({
            id: "Subscription.FreeTrialActiveMsg",
            values: {
              subEndDate: moment(
                this.props.userSubscription.currentPeriodEnd
              ).format("Do MMM YYYY"),
            },
          })}</Typography>
        }
      }
      else if (this.props.userSubscription.status === "active") {
        if (this.props.userSubscription.canceledAt != null) {
          if (this.props.userSubscription.cancelAtPeriodEnd === true) {
            showCancelbutton = false
            subscriptionStatus=showSubscriptionDetails()
          }
        }
        else if (config.AccessCodeMandatory) {
          showCancelbutton = false;
          if (this.props.userSubscription.facCodeEndDate) {
            var parts = this.props.userSubscription.facCodeEndDate.split('-');
            var facCodeEndDate = new Date(parts[2], parts[1] - 1, parts[0]);
            subscriptionStatus =  <Typography variant="h3" className="align-left text-lightgray">{Translate({
              id: "Subscription.FreeAccessCodeWithEndDate",
              values: {
                subEndDate: en_moment(
                  new Date(facCodeEndDate)
                ).format(config?.dateFormat ?? "Do MMM YYYY")
              }, 
            })}</Typography>;
          } else {
            subscriptionStatus =<Typography variant="h3" className="align-left text-lightgray">{Translate({
              id: "Subscription.FreeAccessCodeNoEndDate",
            })}</Typography>;
          }
        }
        else {
          showCancelbutton = true
          subscriptionStatus = showSubscriptionDetails()
        }
      } else {
        subscriptionStatus = <Typography variant="h3" className="align-left text-lightgray">
          {Translate({
          id: "Subscription.NoActiveSubscription",
        })}</Typography>;
        if (config.AccessCodeMandatory && config.showReActivatebuttonForFAC) {
          showReActivatebutton = true
        }
      }
    } else if (this.props.isSubscriptionActive === false) {
      subscriptionStatus = <Typography variant="h3" className="align-left text-lightgray">
        {Translate({
        id: "Subscription.NoActiveSubscription",
      })}</Typography>;
      if (config.AccessCodeMandatory && config.showReActivatebuttonForFAC) {
        showReActivatebutton = true
      }
    }
    else{
      // subscriptionStatus = <Typography variant="h3" className="align-left text-lightgray">{Translate({
      //   id: "Subscription.NoActiveSubscription",
      // })}</Typography>;
      if (config.AccessCodeMandatory && config.showReActivatebuttonForFAC) {
        showReActivatebutton = true
      }
    }

    return (
      <div>
        <Fade
          in={this.props.loading}
          style={{
            transitionDelay: this.props.loading ? "800ms" : "0ms"
          }}
          unmountOnExit
        >
          <Spinner backColor="white" />
        </Fade>
        {/* <ProfileWrapper history={this.props.history} /> */}
        <Grid container spacing={0}>
          <div className="form-container m-t-xs-account">
            <Grid item>
              {/* <Grid item xs={12} sm={12}>
                <Typography variant="h1" className="m-b-20 m-t-5">
                  {Translate({ id: "UserAccount.Title" })}
                </Typography>
              </Grid> */}
              <Grid container direction="column" className="m-t-account">

                <div className="alignleft clear">
                  <Grid container direction='row' justifyContent='space-between'>
                    <div >
                      <Typography variant="h2" className="subscribe-input">
                        {Translate({ id: "Profile.Title" })}
                      </Typography>
                    </div>
                    {config.showSignUpPage === false ? (<div></div>) : (
                      <Typography
                        variant='subtitle2'
                        color="secondary"
                        className="alignright valign-end button-link makeTextClickable"
                        onClick={this.editProfileClickHandler}
                      >
                        {Translate({ id: "Actions.Edit" })}
                      </Typography>)}
                  </Grid>
                </div>
                <hr className="hrow" />
              </Grid>

              <div className="">
                <Grid
                  className="mbottom-why57 align-left"
                  item
                  xs={12}
                  sm={12}
                >
                  <Grid container spacing={0} >

                    <Grid container className="">
                      <Typography variant="body1" className="subscribe-field">
                        {this.props.userDetail != null
                          ? this.props.userDetail.firstName
                          : ""}
                      </Typography>
                      <hr className="hrow" />

                      <Typography variant="body1" className="subscribe-field">
                        {this.props.userDetail != null
                          ? this.props.userDetail.lastName
                          : ""}
                      </Typography>
                      <hr className="hrow" />
                    </Grid>
                    <Grid
                      container className=""
                    >
                      <Typography variant="body1" className="subscribe-field">
                        {this.props.userDetail != null
                          ? this.props.userDetail.emailAddress
                          : ""}
                      </Typography>
                      {/* <hr className="hrow" />
                      <Typography variant="body1" className="subscribe-field password">
                        {this.props.userDetail && this.props.userDetail.password
                          ? this.props.userDetail.password
                          : "........."}
                      </Typography> */}
                      <hr className="hrow" />
                    </Grid>
                  </Grid>
                </Grid>
                <div>
                  <Grid >
                    <div className="makeTextClickable"  >
                      <Typography variant="h3" onClick={this.logoutUser} className="button-link m-t-15 m-t-xs-15 align-left" color='secondary'>
                        {Translate({ id: "layout.LogOut" })}
                      </Typography>
                    </div>
                    <div className="clear" />
                  </Grid>
                </div>
                {config.isThirdPartyEnabled ? null :
                  <React.Fragment>
                    <Grid>
                      <div className="alignleft clear" >
                        <Typography variant="h2" className="subscribe-input m-t-subs m-t-xs-15">
                          {Translate({ id: "Subscription.Title" })}
                        </Typography>
                      </div>
                    </Grid>
                    <hr className="hrow" />
                    <Grid
                      className="subText m-t-15 m-t-xs-15"
                      item
                      xs={12}
                      sm={12}
                    >
                      {subscriptionStatus}
                    </Grid>
                  </React.Fragment>
                }
                <Grid container spacing={0} justifyContent='flex-start'>
                  {showCancelbutton ? (

                    <Grid
                      className="subText m-t-5 m-t-xs-15"
                      item
                      xs={12}
                      sm={12}
                    >

                      <div className="makeTextClickable alignleft">
                        <Button className="button-link"
                          onClick={this.handleDialog}>
                          {<Typography variant="h3" color='secondary'>
                            {Translate({ id: "Actions.Cancel" })}
                          </Typography>}
                        </Button>
                      </div>
                      <CustomDialog
                        open={this.state.isDialogOpen}
                        title={Translate({ id: "Subscription.Cancel" })}
                        heading={Translate({ id: "Subscription.CancelText" })}

                      >
                        <Grid container direction="row" className="m-t-40 m-t-xs-20 padding16">

                          <Grid item sm={6} xs={6} className="m-t-5 text-right">
                            <Button
                              onClick={this.handleDialogClose}
                              variant="contained"
                              color="secondary"
                              className="dialogBtn btn-default"
                            >

                              {<Typography variant="subtitle1">{Translate({ id: "Actions.No" })}</Typography>}

                            </Button>
                          </Grid>
                          <Grid item sm={6} xs={6} className="m-t-5 align-left">
                            <Button
                              onClick={() => { this.CancelUserSubscription(); this.handleDialogClose() }}
                              variant="contained"
                              color="secondary"
                              className="dialogBtn btn-default"
                            >
                              {<Typography variant="subtitle1">{Translate({ id: "Actions.Yes" })}</Typography>}

                            </Button>
                          </Grid>
                        </Grid>
                      </CustomDialog>
                    </Grid>
                  ) : (
                      ""
                    )}
                </Grid>
                <Grid container spacing={0} justifyContent='flex-start'>
                  {config.AccessCodeMandatory && config.showReActivatebuttonForFAC && showReActivatebutton ? (

                    <Grid
                      className="subText m-t-5 m-t-xs-15"
                      item
                      xs={12}
                      sm={12}
                    >

                      <div
                        className="makeTextClickable alignleft"
                      >
                        <Button className="button-link"
                          onClick={this.handleFACDialogOpen}>
                          {
                            <Typography variant="h3" color='secondary'>
                              {Translate({ id: "UserAccount.EnterCodeButton" })}
                            </Typography>
                          }

                        </Button>
                      </div>
                      <CustomDialogFAC
                        open={this.state.isFACDialogOpen}
                        title={Translate({ id: "UserAccount.EnterCodeDialogHeading" })}
                        heading={Translate({ id: "UserAccount.EnterCodeDialogSubHeading" })}
                        cancelicon={false}
                        dialogId='account-Fac'
                        handleDialogClose={this.handleFACDialogClose}

                      ><Grid container direction="column" justifyContent="center"
                        style={{ width: "90%", maxWidth: "500px", margin: "auto" }}>

                          <p className="button-link" style={{ textAlign: "left" }}>
                            {<Typography variant="h3" style={{ color: "#fa2200" }}>
                              {this.props.accessCodeErrorMessage}
                            </Typography>}

                          </p>
                          <TextField
                            className=""
                            label={Translate({ id: "UserAccount.EnterCodeInputLabel" })}
                            value={this.state.couponCode}
                            onChange={e =>
                              this.setState({ couponCode: e.target.value })
                            }
                            variant="standard"
                            style={{ width: "100%", paddingBottom: "28px" }}
                          />
                          <Button
                            disabled={!this.state.iscouponCodeFilled}
                            onClick={this.renewSubscription}
                            variant="contained"
                            color="secondary"
                            className="facCodeEnterButton"
                          >
                            {<Typography variant="subtitle1">{Translate({ id: "UserAccount.GetAccess" })}</Typography>}
                          </Button>
                        </Grid>
                      </CustomDialogFAC>
                    </Grid>
                  ) : null}
                </Grid>
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
    );
  }
}

export default Account
