import { useSelector, useDispatch } from "react-redux";
import { setKeywordsOnDemand } from '../store/actions/filterOnDemand';

const useFilterKeywords = () => {
    const dispatch = useDispatch();
    const useFilterKeywordsState = () => ({
        selectedFilters : useSelector(state => state.filterOnDemand.selectedFilters),
        filteredKeywords : useSelector(state => state.filterOnDemand.filteredKeywords),
        filterSearchText : useSelector(state => state.filterOnDemand.filterSearchText),
        loading : useSelector(state => state.onDemand.fllSearchLoading)
    })
    const useFilterKeywordsDispatch = () => ({
        setOnDemandKeywords: (keyword)=> {
            dispatch(setKeywordsOnDemand(keyword))
        },
    })
    return {useFilterKeywordsState, useFilterKeywordsDispatch}
}
export {useFilterKeywords}