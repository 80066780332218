import React, { useEffect, useState } from 'react';
import { Grid, Typography } from "@material-ui/core";
import Workout from '../../../hoc/WorkoutContainer';
import { Translate } from '../../../utils/Translate'
import { isIPAD } from '../../../utils/constants';
import moment from "moment";
import config from "../../../assets/config.json"

const ClassOfTheDay = React.memo(function Cotd(props) {
    const { classOfTheDay } = props;
    const [temData, setTempData] = useState([])
    const [isIpad] = useState(isIPAD)
    const cardVersion = config.cardVersion

    useEffect(() => {
        if (props.classOfTheDay.collectionItems.length > 1) {
            let arr = []
            for (let index = 0; index < props.classOfTheDay.collectionItems.length-1; index++) {
                arr.push(props.classOfTheDay.collectionItems[index])
            }
            arr.length = 3
            setTempData(arr)
        }
    }, [props.classOfTheDay.collectionItems])

    const checkFavStatus = (data) => {
        let classes =data;
        classes.favourite = !classes.favourite
    }
    const cotdDate = ()=>{
       try{
       return moment(classOfTheDay.collectionItems[0].displayDate).format("dddd DD MMMM")
       } catch(e){
       return moment().format("dddd DD MMMM")
       }
    }
    return (
        <React.Fragment>
                <div className='m-b-32 m-b-15'>
                        <h1 className="align-left font-h1 clear m-b-7">
                             {Translate({ id: "homePage.ClassOfTheDayTitle" })}
                        </h1>
                        <h6 className="text-gray font-h6 align-left "  >
                            {Translate({ id: "homePage.ClassOfTheDayDescription" })}
                        </h6>
                </div>
           {cardVersion === 0.1 &&
                <div data-testid={"cotdCards"} className={`list ${props.list}`}>
                     {temData.map((data,index) =>
                            <Workout
                                cardType={"cotd-card"}
                                collectionType={classOfTheDay.collectionType}
                                trailerLinkWeb={data.trailerLinkWeb}
                                trailerLinkMobile={data.trailerLinkMobile}
                                thumbnail={`${data.imageLink}`}
                                title={data.className}
                                duration={data.durationSecond
                                    ? Math.ceil(data.durationSecond / 60)
                                    : 55}
                                level={data.level}
                                displayDate={data.displayDate}
                                show={true}
                                calories={data.calorieBurn}
                                id={data.tag}
                                classCategory={data.classCategory}
                                clicked={()=>props.takeUserToClassDetail(index)}
                                favDisabled
                            />)}
                        </div>
                                }{/* </Grid> */}
                        {/* {isIpad === false && <Grid item xs={12} sm={12} md={12} lg={6} className="loginButtonHolder cotd2 hidden-md">
                            <Grid container spacing={2}>
                                {temData.map((data,index) =>
                                    <Grid data-testid={"cotdSmallCards"} data-testid={"cotdSmallCards"} item xs={12} sm={6} md={3} lg={6} key={Math.random()} >
                                        <Workout
                                            isCotd={true}
                                            isCotd={true}
                                            trailerLinkWeb={data.trailerLinkWeb}
                                            trailerLinkMobile={data.trailerLinkMobile}
                                            thumbnail={`${data.imageLink}`}
                                            title={data.className}
                                            show={true}
                                            calories={data.calorieBurn}
                                            id={data.tag}
                                            classCategory={data.classCategory}
                                            clicked={()=>props.takeUserToClassDetail(index+1)}
                                            favDisabled
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>} */}
{/*
                    </Grid>

            </div> */}

        </React.Fragment>
    );
});



export default ClassOfTheDay;

