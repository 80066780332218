import React from "react";

const componentImage = (props) => (
  <div>
    {console.log(props)}
    <img
      style={{ width: props.width }}
      src={props.imageSrc}
      alt="Logo"
      className="image"
    />
  </div>
);

export default componentImage;
