import React, { Fragment, useReducer, useEffect } from "react";
import { withRouter } from 'react-router'
import "../../components/PlayerAndCarousel/Workout/Workout.css";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { Typography } from "@material-ui/core";
import { Translate } from '../../utils/Translate';
import LiveStreamCard from "./LiveStreamCard";
import ClubCardNew from '../../containers/LiveConnect/ClubCardNew';
import Spinner from '../../components/spinner/spinner'
import moment from "moment";
import OnDemandFilter from "./FllOndemand/OnDemandFilter"
import { LAZY_LOADING_PAGE_SIZE } from "../../utils/constants";
import CustomGrid from "../CustomGrid/CustomGrid";
import { useLiveStreamList } from "../../hooks/useLiveStreamList";
import LoadingClassesShimmer from "../../components/Shimmer/LoadingClassesShimmer";
import LSListShimmer from "../../components/Shimmer/LSListShimmer";

const config = require("../../assets/config.json")


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(1.0),
        margin: "10px 0",
        backgroundColor: '#efeff4',
        borderRadius: "0",
        '@media (max-width:800px)': {
            marginTop:"16px",
            display: 'inline-flex'
        }
    },
}));

const Livestreamlist = ({ showOnDemand, ...props }) => {
    const { useLiveStreamListState, useLiveStreamListDispatch,liveStreamListReducer } = useLiveStreamList();
    const { clubListClasses, recordedClasses, isLoading, lsloading,fetchFailed, isSelectedDate } = useLiveStreamListState();
    const { saveSelectedDate, fetchLSConnectList, resetFetchClubLiveConnect, fetchClubLiveConnect} = useLiveStreamListDispatch();
    const {useLiveStreamListReducer,initialState ,setReducerData}= liveStreamListReducer;
    const [liveStreamListState,liveStreamListDispatch] = useReducer(useLiveStreamListReducer,initialState)
    const {SelectedDate ,showAllData,allData,EventData ,pageCount ,apiLiveSchedulePageCount ,loadMoreAllCalled ,paginatedDataAll ,paginatedData ,allRecordsLeft,hasMoreData ,apiOnDemandPageCount ,paginatedDataOnDemand ,hasMoreDataOnDemand,allRecordsLeftOnDemand,onDemandTotalPages   } = liveStreamListState
    const classes = useStyles();

    useEffect(() => {
        apiOnDemandPageCount !== 1 ? setReducerData(liveStreamListDispatch, {paginatedDataOnDemand : [] ,apiOnDemandPageCount : 1}) 
            : setReducerData(liveStreamListDispatch, {paginatedDataOnDemand : [] })
        if (showOnDemand && (config.connectSource !== 'wexer')) {
            fetchClubLiveConnect()
        } else {
            fetchLSConnectList()
        }

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnDemand])

    useEffect(() => {
        if (clubListClasses) {
            getEventByDate()
            // hasClassonThisDate(SelectedDate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubListClasses])

    useEffect(() => {
        if (Object.keys(isSelectedDate).length > 0) {
            hasClassonThisDate(isSelectedDate)
        }
        else {
            setReducerData(liveStreamListDispatch,{showAllData: true})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (showOnDemand) {
            setReducerData(liveStreamListDispatch, {pageCount : 0 , apiLiveSchedulePageCount:0})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnDemand])

    function getDateChips(count) {
        const cdate = Date.now()
        const oneDayLength = 86400
        const output = []
        for (let i = 0; i < count; i++) {
            const currentDate = new Date(cdate + (i - 1) * 1000 * oneDayLength)
            //const dateItem = currentDate.toString().split(' ')
            if (i === 0) {
                output.push({ chipLabel: Translate({ id: "liveStream.All" }), dateInApiFormat: currentDate, id: 'All' })
            }
            else {
                // output.push({ chipLabel: `${dateItem[0]} ${dateItem[2]} ${dateItem[1]}`, dateInApiFormat: currentDate, id: i })
                output.push({ chipLabel: `${moment(currentDate).format("ddd DD MMM")}`, dateInApiFormat: currentDate, id: i })

            }
        }
        return output;
    }

    function hasClassonThisDate(date) {
        setReducerData(liveStreamListDispatch, {pageCount : 0 , hasMoreData: false})
        if (date.id === 'All') {
            setReducerData(liveStreamListDispatch,{showAllData: true})

            saveSelectedDate({})
            if (allData.length > LAZY_LOADING_PAGE_SIZE && paginatedDataAll.length < allData.length) {
                setReducerData(liveStreamListDispatch, {apiLiveSchedulePageCount : 0, paginatedDataAll: (allData.slice(0, LAZY_LOADING_PAGE_SIZE)), hasMoreData:true})
            }
        }
        else {
            setReducerData(liveStreamListDispatch, {SelectedDate : date.dateInApiFormat , showAllData : false })
            saveSelectedDate(date)

        }
    }

    function getclasses(date) {
        if (!EventData) {
            return []
        }
        return EventData[date.getDate()]
    }

    //Nikhil Gupta 04th Sept,2020
    // modelling the live events data to show the events on the basis of date

    function getEventByDate() {
        var eventByDateObj = {}
        if (clubListClasses && clubListClasses.eventData) {
            setReducerData(liveStreamListDispatch,{allRecordsLeft:(clubListClasses.recordsLeft)})
            for (let i = 0; i < clubListClasses.eventData.length; i++) {
                var cardObj = {
                    activeTime: clubListClasses.eventData[i].live.active_Time,
                    description: clubListClasses.eventData[i].description,
                    eventStatus: clubListClasses.eventData[i].live.status,
                    id: clubListClasses.eventData[i].id,
                    thumbnail: clubListClasses.eventData[i].pictures ? clubListClasses.eventData[i].pictures.sizes[0].link : "",
                    title: clubListClasses.eventData[i].name,
                    duration: clubListClasses.eventData[i].duration,
                    videoId: clubListClasses.eventData[i].videoId,
                    type: clubListClasses.eventData[i].type
                }
                //to show the events on the basis of next_Occurrence_Time
                var date1 = new Date(clubListClasses.eventData[i].next_Occurrence_Time).getDate()
                var today = new Date().getDate()
                if (!eventByDateObj[date1]) {
                    eventByDateObj[date1] = []
                }
                if (!eventByDateObj[date1].some((el) => el.id === clubListClasses.eventData[i].id)) {
                    if (config.connectSource !== 'wexer') {
                        eventByDateObj[date1].push({
                            ...cardObj,
                            eventStatus: "unavailable",
                            next_Occurrence_Time: clubListClasses.eventData[i].next_Occurrence_Time
                        })
                    }
                    else {
                        eventByDateObj[date1].push({
                            ...cardObj,
                            next_Occurrence_Time: clubListClasses.eventData[i].next_Occurrence_Time
                        })
                    }
                }

                //to show the live streaming events on today's chip
                if (clubListClasses.eventData[i].live.status === "streaming" && date1 !== today
                ) {
                    if (!eventByDateObj[today]) {
                        eventByDateObj[today] = []
                    }
                    if (!eventByDateObj[today].some((el) => el.id === clubListClasses.eventData[i].id)) {
                        if (config.connectSource !== 'wexer') {
                            eventByDateObj[today].push({
                                ...cardObj,
                                next_Occurrence_Time: new Date().toISOString().split('T')[0] + 'T' + cardObj.activeTime.split('T')[1]
                            })
                        }
                        else {
                            eventByDateObj[today].push({
                                ...cardObj,
                                next_Occurrence_Time: new Date().toISOString().split('T')[0] + 'T' + clubListClasses.eventData[i].activeTime.split('T')[1]
                            })
                        }
                    }
                }

                // to show the recurring event on available dates
                if (clubListClasses.eventData[i].schedule) {
                    if (clubListClasses.eventData[i].schedule.type !== "single") {
                        if (clubListClasses.eventData[i].schedule.availableDate.length > 0) {
                            for (let j = 0; j < clubListClasses.eventData[i].schedule.availableDate.length; j++) {
                                var reqDate = clubListClasses.eventData[i].schedule.availableDate[j];
                                var addTimeZoneDate = reqDate.split("T")
                                addTimeZoneDate[1] = clubListClasses.eventData[i].schedule.daily_Time
                                var finalDate = addTimeZoneDate.join("T")

                                if (!eventByDateObj[new Date(finalDate).getDate()]) {
                                    eventByDateObj[new Date(finalDate).getDate()] = []
                                }
                                if (!eventByDateObj[new Date(finalDate).getDate()].some((el) => el.id === clubListClasses.eventData[i].id)) {
                                    eventByDateObj[new Date(finalDate).getDate()].push({
                                        ...cardObj,
                                        eventStatus: "unavailable",
                                        next_Occurrence_Time: finalDate
                                    })
                                }
                            }
                        }
                    }
                }
            }
            setReducerData(liveStreamListDispatch,{EventData : eventByDateObj})
            let allData = []
            let currentDay = moment().add(0, 'day').hour(0o0).minute(0o1)
            for (let i in eventByDateObj) {
                if (eventByDateObj[i] && eventByDateObj[i].length > 0) {
                    for (let j of eventByDateObj[i]) {
                        if (config.connectSource === 'wexer') {
                            if (dateDifference(currentDay, j.activeTime.toString().substring(0, 10)) < 7)
                            allData = [...allData, j]
                        }
                        else
                        allData = [...allData, j]
                    }
                }
            }
            setReducerData(liveStreamListDispatch,{ allData : (allData.sort((a, b) => new Date(a.next_Occurrence_Time) - new Date(b.next_Occurrence_Time)))})
        }
    }

    const dateDifference = (currentDay, dateParam) => {
        let differenceInTime = new Date(dateParam).getTime() - new Date(currentDay).getTime();
        let differenceInDays = parseInt(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    }

    const loadMoreAll = () => {
        if (showAllData)
            setTimeout(() => {
                if (allData.length > 0 && !loadMoreAllCalled) {
                    setReducerData(liveStreamListDispatch,{loadMoreAllCalled : true})
                    let toFilterClasses = allData
                    if (pageCount < toFilterClasses.length) {
                        let data = toFilterClasses.slice(0, pageCount + LAZY_LOADING_PAGE_SIZE)
                        setReducerData(liveStreamListDispatch, {pageCount : (pageCount + LAZY_LOADING_PAGE_SIZE), paginatedDataAll: data})
                    }
                    else if (allRecordsLeft > 0) {
                        fetchLSConnectList(apiLiveSchedulePageCount + 1)
                        setReducerData(liveStreamListDispatch,{apiLiveSchedulePageCount: apiLiveSchedulePageCount+1})
                    }
                    else {
                        setReducerData(liveStreamListDispatch,{ hasMoreData : false})
                    }
                }
                else {
                    setReducerData(liveStreamListDispatch,{ loadMoreAllCalled : false})
                }
            }, 1000);
        else {
            setTimeout(() => {
                if (EventData) {
                    let toFilterClasses = EventData[SelectedDate.getDate()]
                    if (pageCount < toFilterClasses.length) {
                        let data = toFilterClasses.slice(0, pageCount + LAZY_LOADING_PAGE_SIZE)
                        setReducerData(liveStreamListDispatch, {pageCount : (pageCount + LAZY_LOADING_PAGE_SIZE), paginatedData: data})
                    }
                    else {
                        setReducerData(liveStreamListDispatch,{ hasMoreData : false})

                    }
                }
            }, 1000);
        }
    }

    useEffect(() => {
        if (paginatedDataAll.length > 0) {
            if (allData.length <= paginatedDataAll.length) {
                setReducerData(liveStreamListDispatch,{ hasMoreData : false})

            }
            else {
                if (Object.keys(isSelectedDate).length === 0)
                    setReducerData(liveStreamListDispatch,{ hasMoreData : true})

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatedDataAll])

    useEffect(() => {
        if (paginatedData.length > 0&&EventData.length>0) {
            if (EventData[SelectedDate.getDate()].length <= paginatedData.length) {
                setReducerData(liveStreamListDispatch,{ hasMoreData : false})

            }
            else {
                setReducerData(liveStreamListDispatch,{ hasMoreData : true})

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatedData])

    useEffect(() => {
        if (allData.length > 0) {
            setReducerData(liveStreamListDispatch,{paginatedDataAll:(allData.slice(0, LAZY_LOADING_PAGE_SIZE))})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allData])

    useEffect(() => {
        if (EventData) {
            if (EventData[SelectedDate.getDate()])
                setReducerData(liveStreamListDispatch,{paginatedData :(EventData[SelectedDate.getDate()].slice(0, LAZY_LOADING_PAGE_SIZE))})

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedDate])

    useEffect(() => {
        if (config.connectSource !== 'wexer') {
            if (recordedClasses && recordedClasses.data && recordedClasses.data.length > 0) {
                setReducerData(liveStreamListDispatch,{ allRecordsLeftOnDemand : (recordedClasses.recordsLeft)})
                let totalPages = (recordedClasses.totalRecords / recordedClasses.perPage) + 1
                setReducerData(liveStreamListDispatch,{ onDemandTotalPages : totalPages})

                if (recordedClasses.recordsLeft > 0) {
                    setReducerData(liveStreamListDispatch,{ hasMoreDataOnDemand : true})

                } else {
                    setReducerData(liveStreamListDispatch,{ hasMoreDataOnDemand : false})

                }
                if (paginatedDataOnDemand.length === 0)
                    setReducerData(liveStreamListDispatch, {paginatedDataOnDemand : (recordedClasses.data) })
                else {
                    setReducerData(liveStreamListDispatch, {paginatedDataOnDemand : ([...paginatedDataOnDemand, ...recordedClasses.data]) })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordedClasses])

    useEffect(() => {
        //reset recorded classes component at unmount 
        return () => {
            resetFetchClubLiveConnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadMoreOnDemand = () => {
        setTimeout(() => {
            if (config.connectSource !== 'wexer') {
                if (recordedClasses && recordedClasses.data && recordedClasses.data.length > 0) {
                    setReducerData(liveStreamListDispatch,{ hasMoreDataOnDemand : false})
                    if (allRecordsLeftOnDemand > 0 && onDemandTotalPages >= apiOnDemandPageCount) {
                        setReducerData(liveStreamListDispatch,{ apiOnDemandPageCount : (apiOnDemandPageCount + 1)})
                    }
                    else {
                        setReducerData(liveStreamListDispatch,{ hasMoreDataOnDemand : false})

                    }
                }
            }
        }, 1000);
    }

    useEffect(() => {
        if (apiOnDemandPageCount > 1) {
            fetchClubLiveConnect(apiOnDemandPageCount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiOnDemandPageCount])
    const sorting = (items) => {
        return items.sort(
            (a, b) =>
                new Date(a.next_Occurrence_Time) -
                new Date(b.next_Occurrence_Time)
        )
    }
    const isError = (fetchFailed || EventData) && !showOnDemand ? fetchFailed || (showAllData && allData.length < 1) || (!showAllData && (!EventData[SelectedDate.getDate()] || EventData[SelectedDate.getDate()].length === 0)) : false;
    return (
        <div>
            {isLoading || lsloading ?
                <LSListShimmer /> :
                <Fragment>
                    <div className={`page-container onDemand-description ${showOnDemand ? "content-desc":" "}`}>
                        {/* <Typography variant="h1" className="m-t-xs-15">{Translate({ id: showOnDemand ? "homePage.ConnectTitle" : "homePage.LiveScheduleTitle" })} </Typography> */}
                        <Grid container direction="row">
                            <Grid item lg={12} md={12} sm={12} xs={12} className="align-left hidden-xs">
                                <Typography className="text-gray padding16" variant="h6" >
                                    {Translate({ id: showOnDemand ? "homePage.ConnectDescription" : "homePage.LiveScheduleDescription" })}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    {!showOnDemand &&
                        <Grid container>
                            <Grid item lg={12} md={12} className="mobileScrollParent m-b-xs-5">

                                <div className="mobileScroll">
                                    <Paper component="ul" className={classes.root}>
                                        {getDateChips(8).map((data, key) => {
                                            return (
                                                <li key={key} className={`${config.name} liveStreamChips`}>
                                                    <Chip
                                                        label={data.chipLabel}
                                                        onClick={() => (hasClassonThisDate(data))}
                                                        className={`${(data.dateInApiFormat.getDate() === SelectedDate.getDate() && !showAllData) || (showAllData && data.id === 'All') ? "text-black" :
                                                            (getclasses(data.dateInApiFormat) && getclasses(data.dateInApiFormat).length > 0) || data.id === 'All' ? "" : "disabledText"}`}>
                                                    </Chip>
                                                </li>
                                            );
                                        })}
                                    </Paper>
                                </div>

                            </Grid>
                        </Grid>
                    }
                    {(fetchFailed || EventData) && !showOnDemand &&
                        <CustomGrid
                            classes=""
                            containerClass="live"
                            hasMore={hasMoreData}
                            items={showAllData ? paginatedDataAll : sorting(paginatedData)}
                            totalItems={hasMoreData ? paginatedDataAll.length : LAZY_LOADING_PAGE_SIZE}
                            loadMore={loadMoreAll}
                            showLoading={false}
                            loader={hasMoreData ? <LoadingClassesShimmer key={Math.random()}/> : null }
                            isError={isError}
                            listClass="list-live-page"
                        >
                            {isError ?
                                <Typography className="text-gray p-t-32 m-t-xs-15 full-width m-l-5" variant="h6" >
                                    {Translate({ id: "liveStream.NoResult" })}
                                </Typography>
                                :(cardData, index) =><LiveStreamCard
                                    nextOccurence={cardData.next_Occurrence_Time}
                                    activeTime={cardData.activeTime}
                                    eventStatus={cardData.eventStatus}
                                    history={props.history}
                                    thumbnail={cardData.thumbnail}
                                    title={cardData.title}
                                    description={cardData.description}
                                    show={true}
                                    id={cardData.id}
                                    videoId={cardData.videoId}
                                    duration={
                                        cardData.duration
                                            ? Math.ceil(cardData.duration / 60)
                                            : 55
                                    }
                                    durationInSec={cardData.duration}
                                    type={cardData.type}
                                />
                            }
                        </CustomGrid>
                    }
                    {showOnDemand && paginatedDataOnDemand && config.connectSource !== 'wexer' &&
                        <CustomGrid
                            classes="m-t-5 m-t-xs-5"
                            hasMore={hasMoreDataOnDemand}
                            items={paginatedDataOnDemand}
                            totalItems={paginatedDataOnDemand.length}
                            loadMore={loadMoreOnDemand}
                            showLoading={showOnDemand && hasMoreDataOnDemand}
                        >
                            {(clubData) => <ClubCardNew clubData={clubData} />}
                        </CustomGrid>
                    }
                    {config.connectSource === 'wexer' && showOnDemand && <OnDemandFilter />}
                </Fragment>
            }
        </div >
    )
}
export default withRouter(Livestreamlist);

