import React, { Component } from 'react'
import "../../theme/styles/image-gallery.css";
import { withRouter } from 'react-router-dom';
// import Classes from '../PlayerAndCorousel/views/ClasslistView/ClasslistView';
import Classes from "../../hoc/ClasslistViewContainer";
// import { FirebaseApp } from '../../FirebaseApp';

class OnDemandPage extends Component {
  componentWillUnmount(){
    // this.firebaseApp.traceData.stop();
  }
  componentDidMount() {
    // this.firebaseApp = new FirebaseApp();
    // this.firebaseApp.setUp("landing_page");
    // this.firebaseApp.setAttribute({key:"landingpage",value:"A"});
    
    // this.firebaseApp.traceData.start();
  }
  render() {
    return (
        <div className="App">
        <Classes/> 
                 {/* {this.props.location.pathname !== '/ondemand/player' &&    
                         <SubscriptionComponent></SubscriptionComponent>
                 } */}
        </div>
    );
  }
}

export default withRouter(OnDemandPage);
