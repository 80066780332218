import { useDispatch, useSelector } from "react-redux";
import { AuthenticateThirdPartyUser } from "../ClubManager";
import * as actionTypes from "../store/actions/actionTypes";
import { setAppLoadEndErrorEvent, setAppLoadEndEvent } from "../store/actions/webPlayer";

const useEmbedded = () => {
    const dispatch = useDispatch();
    const useEmbeddedState = ()=> ({
        isAuthenticated : useSelector((state) => state.auth.isAuthenticated),
        loading : useSelector((state) => state.auth.auth_loading),
        errorMessage : useSelector(state => state.webPlayerEvents?.errorMessage),
        showErrorMsg : useSelector((state) => state.auth.showErrorMsg)
    });
    const useEmbeddedDispatch = () => ({
        setEmbeddedMode: (embededConfig)=>{
            dispatch({
                type: actionTypes.SET_EMBEDDED_MODE,
                embededConfig,
            })
        },
        authenticThirdyPartyPlayer:(token)=>{
            dispatch(AuthenticateThirdPartyUser({ token}));
        },
        logOut:()=>{
            dispatch({type: actionTypes.AUTH_LOGOUT });
        },
        loadEndErrorEvent:(msg)=>{
            dispatch(setAppLoadEndErrorEvent(msg));
        },
        loadEndEvent:()=>{
            dispatch(setAppLoadEndEvent());
        }
    })
    return {useEmbeddedState, useEmbeddedDispatch}
}
export {useEmbedded}