import {find,includes} from 'lodash';
import { useState } from 'react';
const getSize = (currSize)=>{
  let screenWidth = window.innerWidth;
  const breakPoints = [{screenWidth:768,index:0,size:["24px","32px"]},
  {screenWidth:1360,index:1,size:["32px","32px"]},
  {screenWidth:2000,index:2,size:["32px","40px"]},
  {screenWidth:2001,index:3,size:["32px","48px"]},
]
const checkBreakPoint=(obj)=>{
  const minWidth = obj.index === 0 ? 375: breakPoints[obj.index-1]?.screenWidth
  return screenWidth >= 2000 ?  obj.screenWidth === 2001 : minWidth < screenWidth && screenWidth <= obj.screenWidth 
}
const getBreakPoint = find(breakPoints,checkBreakPoint)
const isCurrentSizeExist = includes(getBreakPoint?.size,currSize[getBreakPoint?.index])
return isCurrentSizeExist ? currSize[getBreakPoint?.index] : "32px";

}
export function useIconSize(currSize,svgId) {

  window.addEventListener("resize", ()=>{
    try{     
      const svgEle = document.getElementById(svgId);
      const sizeInfo = getSize(currSize)
      svgEle.setAttribute("width", sizeInfo)
      svgEle.setAttribute("height", sizeInfo)
    }
    catch(e){
      console.log(svgId,e.message,"===CATCH")
    }
  })
  return getSize(currSize);
}
// export function usesvgIconSize(currSize,svgId,svgIds=false) {
//   const [sizeState, setSizeState] = useState(currSize)

//   const getSizeState = () => sizeState
//   window.addEventListener("resize", ()=>{
//     try{
//       const sizeInfo = getSize(currSize);
//       const getSvgId = () =>{
//         if(svgIds) {
//          return sizeInfo === "48px" ? svgIds[0] :svgIds[1]
//         }
//         else{
//           return svgId
//         }
//       }
//       const svgEle = document.getElementById(getSvgId());
//       setSizeState(sizeInfo)
//       svgEle.setAttribute("width", sizeInfo)
//       svgEle.setAttribute("height", sizeInfo)
//     }
//     catch(e){
//       console.log(svgId,e.message,"===CATCH")
//     }
//   })
//   return getSize(currSize);
// }
export function iconSizeLarge(currSize) {
    let screenWidth = window.screen.width
    let size 
            if(screenWidth <1360 && currSize !== "45px"){
              size = "45px"
            }
            if(screenWidth >1360 && currSize !== "72px"){
                size = "72px"
            }
        return size;
  }

export function iconSizeSmall(currSize) {
    let screenWidth = window.screen.width
    let size 
            if(screenWidth <1360 && currSize !== "24px"){
              size = "24px"
            }
            if(screenWidth >1360 && currSize !== "32px"){
                size = "32px"
            }
        return size;
  }

