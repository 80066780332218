import Home from "../containers/HomePage/HomePage"
import { setNavigatedFromWhichScreen } from '../store/actions/auth';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { AuthenticateThirdPartyUser } from "../ClubManager";
const mapStateToProps = state => {
    return {
      userId: state.auth.userId,
      isAuthenticated: state.auth.token,
      embeddedConfig: state.auth.embeddedConfig
  
    };
  };
  
  const mapDispatchToProps=dispatch=>{
    return{
      AuthenticateThirdPartyUser: () => dispatch(AuthenticateThirdPartyUser()),
      setNavigatedFromWhichScreen:()=>dispatch(setNavigatedFromWhichScreen(''))
    }
  }

  export default withRouter(connect(mapStateToProps,mapDispatchToProps)(injectIntl(Home)));