import React from 'react'
import {Helmet} from "react-helmet";
const configJson = require("../../../src/assets/config.json")
const config = require("../../../src/assets/ClubManager/config.js"); 
const AddDependency = () => {
    const {JWPLAYER = 'wUkkXJdm.js'} = config;
    const {jwJSPlayerId} = configJson;
    const cdnPlayer = `https://cdn.jwplayer.com/libraries/${jwJSPlayerId ?? JWPLAYER}`;
    return (
        <Helmet>
            <script src={cdnPlayer}></script>
        </Helmet>
    )
}

AddDependency.propTypes = {

}

export {AddDependency}
