import { useDispatch, useSelector } from "react-redux";
import { setKeywords } from '../store/actions/filter';


const useFilter = () => {
    const dispatch = useDispatch();
    const useFilterState = () => ({
        selectedFilters : useSelector(state => state.filter.selectedFilters),
        filteredKeywords : useSelector(state => state.filter.filteredKeywords),
        filterSearchText : useSelector(state => state.filter.filterSearchText),
        loading : useSelector(state => state.onDemand.searchLoading)
    })
    const useFiltertDispatch = () => ({
        setKeywords: (keyword)=> {
            dispatch(setKeywords(keyword));
        },
    })
    return {useFilterState, useFiltertDispatch}
}
export {useFilter}