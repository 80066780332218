import React, { Component } from "react";
import "./ClasslistView.css";
import Workout from "../../../../hoc/WorkoutContainer";
import "../../../../theme/styles/react-select.min.css"
import Spinner from "../../../../components/spinner/spinner";
import { Translate } from "../../../../utils/Translate";
import { Grid, Typography } from "@material-ui/core";
import Routes from '../../../../components/Router/Routes'
import logger from "../../../../utils/logger";
import ProfileWrapper from "../../../../containers/User/ProfileWrapper";
import InfiniteScroll from "react-infinite-scroller";
import LoadingClassesShimmer from "../../../../components/Shimmer/LoadingClassesShimmer";
import { LAZY_LOADING_PAGE_SIZE } from "../../../../utils/constants";

//var classObjList = [];

class ClasslistViewFav extends Component {
  constructor() {
    super();
    this.state = {
      classes: [],
      prevPlayer: null,
      isPrevPlaying: false,
      isFavStatusChanged: false,
      hasMoreData: false,
      paginatedData: [],
      pageCount: 0
    };

  }

  checkFavStatus = (id) => {
    logger(id)
    //let classes = this.state.classes
    let classIndex = this.state.classes.findIndex(particularclass => particularclass.id === id)
    this.state.classes.splice(classIndex, 1)
    let newData=JSON.parse(JSON.stringify(this.state.classes));
    if(this.state.classes.length>0){
      if(this.state.classes.length <= LAZY_LOADING_PAGE_SIZE)
      {
        this.setState({hasMoreData:false, isFavStatusChanged: !this.state.isFavStatusChanged,paginatedData:newData.splice(0,LAZY_LOADING_PAGE_SIZE)  });
      }
      else
       { 
         if(this.state.pageCount>=this.state.classes.length&&!this.state.hasMoreData)
          {
            this.setState({isFavStatusChanged: !this.state.isFavStatusChanged,paginatedData: newData });
          }
          else
          {
            this.setState({ hasMoreData:true,isFavStatusChanged: !this.state.isFavStatusChanged,paginatedData: newData.splice(0,LAZY_LOADING_PAGE_SIZE) });
          }
        }
    }
    else{
      this.setState({ hasMoreData:false,isFavStatusChanged: !this.state.isFavStatusChanged,paginatedData: newData });
    }  
  }
  takePreviousPlayer = data => {
    this.setState({ prevPlayer: data });
    logger(data);
  };

  componentDidMount() {
    this.props.resetFilters()
    this.props.resetFiltersOnDemand()
    this.props.onDemandDataReset()
    this.props.browseClassesReset()
    var userId = localStorage.getItem("userId");
    // if (userId !== null && userId !== undefined) {
    //   this.props.onOnDemandClassesLoad(userId);
    // } else {
    //   this.props.onOnDemandClassesLoad("");
    // }
    this.setState({paginatedData:[] });
    this.props.getFavouritedClasses()
  }

  componentDidUpdate(prevProps) {
    logger(this.props.favoriteClasses)
    if (prevProps.favoriteClasses !== this.props.favoriteClasses && this.props.favoriteClasses!== null) {
      if (prevProps.favoriteClasses !== null) {
        if (this.props.favoriteClasses) {
          let classObjList = [];
          let itemList = this.props.favoriteClasses;
          itemList.forEach(c => {
            const obj = {
              id: c.tag,
              thumbnail: c.imageLink,
              title: c.className,
              show: c.isActive,
              category: c.classCategory,
              fav: true,
              match: true,
              duration: c.durationSecond ? Math.floor(c.durationSecond / 60) : 55,
              provider_id: c.provider,
              key: c.tag,
              description: c.classDescription,
              instructor: c.instructor,
              calories: c.calorieBurn,
              alternateLink: c.alternateLink,
              intensity: c.intensity,
              skill: c.skill,
              creationDate: c.creationDate,
              scheduleDate:c.scheduleDate,
              trailerLinkMobile: c.trailerLinkMobile,
              trailerLinkWeb: c.trailerLinkWeb,
              equipmentTypes: c.equipmentTypes,
              equipmentNames: c.equipmentNames,
              newProviderId: c.providerID,
              keywords: c.keywords,
              level: c.level
            };
            classObjList.push(obj);
          });
          this.setState({classes: classObjList,paginatedData: classObjList.slice(0, LAZY_LOADING_PAGE_SIZE) });
        }
      } else {
        let classObjList = [];
        let itemList = this.props.favoriteClasses;
        itemList.forEach(c => {
          const obj = {
            id: c.tag,
            thumbnail: c.imageLink,
            title: c.className,
            show: c.isActive,
            category: c.classCategory,
            fav: true,
            match: true,
            duration: c.durationSecond ? Math.floor(c.durationSecond / 60) : 55,
            provider_id: c.provider,
            key: c.tag,
            description: c.classDescription,
            instructor: c.instructor,
            calories: c.calorieBurn,
            alternateLink: c.alternateLink,
            intensity: c.intensity,
            skill: c.skill,
            creationDate: c.creationDate,
            scheduleDate:c.scheduleDate,
            trailerLinkMobile: c.trailerLinkMobile,
            trailerLinkWeb: c.trailerLinkWeb,
            equipmentTypes: c.equipmentTypes,
            equipmentNames: c.equipmentNames,
            newProviderId: c.providerID,
            keywords: c.keywords,
            level: c.level
          };
          classObjList.push(obj);
        });
        if(classObjList.length>0){
            if(classObjList.length <= LAZY_LOADING_PAGE_SIZE)
                this.setState({ hasMoreData: false, classes: classObjList, paginatedData: classObjList.slice(0, LAZY_LOADING_PAGE_SIZE) });
            else
                this.setState({ hasMoreData: true, classes: classObjList, paginatedData: classObjList.slice(0, LAZY_LOADING_PAGE_SIZE) });
        }
        else{
            this.setState({  hasMoreData: false});
        }  
      }
    }
    else {
      return false;
    }
  }

  onClickHandler = id => {
    const item = this.state.classes.find(cl => cl.id === id);
    if (item.duration)
      item.durationSecond = item.duration * 60
    this.props.history.push({
      pathname: Routes.onDemandClassPlayerRoute + id,
      state: { selectedItem: item, prevPath: Routes.favClassRoute },
    });
  };

  loadMore = () => {
    if (this.state.classes) {

      setTimeout(() => {
        let toFilterClasses = this.state.classes
        if (this.state.pageCount < toFilterClasses.length) {
          //setTimeout(() => {
          let data = toFilterClasses.slice(0, this.state.pageCount + LAZY_LOADING_PAGE_SIZE)

          if (toFilterClasses.length <= data.length) {
            this.setState({
              hasMoreData: false,
              paginatedData: data,
              pageCount: this.state.pageCount + LAZY_LOADING_PAGE_SIZE
            })
          }
          else {
            this.setState({
              hasMoreData: true,
              paginatedData: data,
              pageCount: this.state.pageCount + LAZY_LOADING_PAGE_SIZE
            })
          }
          //}, 1000);
        }
        else {
          this.setState({
            hasMoreData: false
          })
        }
      }, 1000);
    }
  }

  render() {
    let classes;
    if (this.state.classes.length === 0) {
      classes = (
        <div className="no-classes-found">
          <Typography variant="body2" className="text-gray">{Translate({ id: "ClassListViews.NoFavMessage" })}</Typography>
        </div>
      );
    } else {
      classes = (
        <div container
          justifyContent="flex-start" spacing={4} direction="row" className="list list-live-page clear m-t-40 m-t-xs-5"
        >
          {this.state.paginatedData.map((c, index) =>
            c.show ? (
              <div item xs={12} sm={12} md={6} lg={4}
                className="cardAlign" key={Math.random()} >
                <Workout
                  cardType="channel-page-card"
                  collectionType="featured"
                  id={c.id}
                  keywords={c.keywords}
                  isPrevPlaying={this.state.isPrevPlaying}
                  takePreviousPlayer={this.takePreviousPlayer}
                  prevPlayer={this.state.prevPlayer}
                  isSearchPageCard={true}
                  displayDate={c.scheduleDate}
                  trailerLinkMobile={c.trailerLinkMobile}
                  trailerLinkWeb={c.trailerLinkWeb}
                  classCategory={c.category}
                  calories={c.calorieBurn}
                  equipmentTypes={c.equipmentTypes}
                  equipmentNames={c.equipmentNames}
                  key={index}
                  thumbnail={`${c.thumbnail}`}
                  title={c.title}
                  duration={c.duration}
                  description={c.description}
                  clicked={() => this.onClickHandler(c.id)}
                  show={true}
                  level={c.level}
                  isFav={c.fav}
                  collectionName={"Favourites"}
                  favCallback={() => { this.checkFavStatus(c.id) }}
                />
              </div>
            ) : null
          )}
        </div>
      );
    }

    return (
        <div className="">
          {
            this.props.loading? <LoadingClassesShimmer myClass="" key={Math.random()} />:
            <InfiniteScroll
              pageStart={0}
              datalength={LAZY_LOADING_PAGE_SIZE}
              loadMore={this.loadMore}
              hasMore={this.state.hasMoreData}
              loader={this.state.hasMoreData && !this.props.loading ? <LoadingClassesShimmer key={Math.random()} /> : null}
            >
              {classes}
            </InfiniteScroll>
          }
        </div>
    );
  }
}

export default ClasslistViewFav
