import { useDispatch, useSelector } from "react-redux";
import {setClassesSearchData,setPaginatedData,resetFilters} from "../store/actions/filter"
import {resetBrowseClassData} from "../store/actions/ondemand";

const useBrowseClasses = () => {
    const dispatch = useDispatch();
    const useBrowseClassesState = () => ({
        metadata : useSelector(state => state.onDemand.Metadata)
    })
    const useBrowseClassesDispatch = () => ({
        resetBrowseClassData : () => {
            dispatch(resetBrowseClassData())
        },
        setClassesSearchData : () => {
            dispatch(setClassesSearchData())
        },
        setPaginatedData : () => {
            dispatch(setPaginatedData())
        },
        resetFilters : () => {
            dispatch(resetFilters())
        },
    })
    return {useBrowseClassesState, useBrowseClassesDispatch}
}
export {useBrowseClasses}