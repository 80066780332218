import React from "react";
import { withRouter } from "react-router";
import ChannelsCardWrapper from "./ChannelsCardWarpper";
import { Grid, Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Routes from "../../../src/components/Router/Routes";
import ResCard from "../../CardVersions/version1/newcard";
import config from '../../assets/config.json';
import IconRigntArrow from '../../CommonImages/icon-rightArrow'

class ChannelsHome extends React.Component {
  
  //  gotoPlayer=()=>{
  //      this.props.history.push({
  //          pathname:Routes.onDemandClassPlayerRoute + "",
  //      })
  //  }

  viewDetails = () => {
    this.props.history.push({
      pathname: "/channels",
      state: { clubClasses: this.props.clubClasses },
    });
  };
  ViewChannel = () => {
    this.props.history.push({
      pathname: Routes.channelDetailView,
      state: { clubClasses: this.props.clubClasses },
    });
  };

  render() {
    const cardVersion = config.cardVersion
    const data = {
      collectionType:"channel",
      collectionItems:this.props.channelData
    }
    return (
       <div >       
          <div className="m-t-60 ">
                <div className='m-b-32 m-b-15'>
                    <div className="align-left d-flex makeTextClickable m-b-7" onClick={this.viewDetails}>
                        <h1 className="align-left font-h1 "> {Translate({ id: "homePage.ChannelsTitle" })} </h1>
                        <IconRigntArrow id='arrow-5' />
                    </div>
                    <h6 className="text-gray font-h6 align-left "  >
                    {Translate({id:"homePage.ChannelsDescription"})}
                    </h6>
                </div>    
         </div>
          <div className="mobileScroll m-t-15">      
          { cardVersion === 0.0 &&<Grid
            container
            justifyContent="flex-start"
            spacing={4}
            className="bg-white m-t-xs-5 flexNoWrap cardList"
          >
            { cardVersion === 0.0 && this.props.channelData.map(
              (channelData, index) =>
                -index < 3 && (
                  <Grid item lg={4} md={6} sm={12} xs={12} key={index}  className="channelHome">
                    <ChannelsCardWrapper
                        data={channelData}                      
                    />
                  </Grid>
                )
            )}
          </Grid>}
          {cardVersion === 0.1 &&  <div className={`list`}>{this.props.channelData.map(
              (channelData, index) =>
                -index < 3 && (<ChannelsCardWrapper data={channelData} cardType="live-channel-collection" />)
              )}          
          </div>}
          </div>
          </div>        
    );
  }
}

export default withRouter(ChannelsHome);
