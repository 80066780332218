import React from 'react'
import { Skeleton } from '@material-ui/lab'
import CardShimmer from './CardShimmer'

const MoreClassesShimmer = () => {
  return (
    <div data-testid={"moreCLassesShimmer"} className="liveShimmer mobileScroll m-t-15">
    <div className='d-flex m-b-10 m-more-head'>
        <div className="more-img">    
        <Skeleton variant="circle" width={"100%"} height={"100%"} />
        </div>
        <Skeleton variant="text" width={'40%'} height={25} />
    </div>
        <CardShimmer noOfCards={[1,2,3,4,5]} footer={true}/>
 </div>
  )
}

export default MoreClassesShimmer