import React from "react"

function IconClose(props){
    const color = (require("../assets/config.json")).primaryColor.slice(1)

    const src = `<svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="M36.6 11.42a1.992 1.992 0 0 0-2.82 0L24 21.18l-9.78-9.78a1.992 1.992 0 0 0-2.82 0c-.78.78-.78 2.04 0 2.82L21.18 24l-9.78 9.78c-.78.78-.78 2.04 0 2.82.78.78 2.04.78 2.82 0L24 26.82l9.78 9.78c.78.78 2.04.78 2.82 0 .78-.78.78-2.04 0-2.82L26.82 24l9.78-9.78c.76-.76.76-2.04 0-2.8z" id="r0sbme9raa"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <mask id="jck9e8cfrb" fill="%23fff">
            <use xlink:href="%23r0sbme9raa"/>
        </mask>
        <g mask="url(%23jck9e8cfrb)" fill="%23${color}">
            <path d="M0 0h48v48H0z"/>
        </g>
    </g>
</svg>
`
    return (
    <img src={`data:image/svg+xml;utf8,${src}`} className={props.cls}  onClick={props.onClick}/>
    )
}

export default IconClose
