/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useEffect,useState } from "react";
import Image from "../../components/Image/Image";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { Translate } from "../../utils/Translate";
import { Typography } from "@material-ui/core";
import Routes from '../Router/Routes'
import BrowseClasses from "../../containers/BrowseClasses/BrowseClasses";
import BrowseClassesShimmer from "../Shimmer/BrowseClassesShimmer";

const ImageList = props => {
  
  const [showShimmer,setShowShimmer] = useState(true)

  useEffect(()=>{
    setTimeout(() => {
      setShowShimmer(false)
    }, 1500);
  },[])

 return <div className="">
    <div className="EContainer">
      <div className="m-bt-lg-60  class-title-text-holder">
        <Typography variant="h1" className=" imgTitle p-gt-sm-5 market-tag-h1"
          style={
            {
              // ...FontStyle.SFProDisplayMedium,
              marginBottom: "0px"
            }}>{props.titleText}</Typography>
        <Typography className="imgSubTitle m-t-5 p-gt-sm-5"
          variant="h3"
          >
          {Translate({ id: "homePage.ClassSubTitleText" })}
        </Typography>
        <div className="bgContainer homeStripe p-t-32">
      <div className="lp-browse-class-container">
       {showShimmer ? <BrowseClassesShimmer fromLandingPage={true} /> : <BrowseClasses  fromLandingPage={true}/>}
      </div>
      </div>
      </div>
    </div>
  </div>
}

export default withRouter(ImageList);
