import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from "@material-ui/core";

const BrowseClassesShimmer = (props) => {

    return (
        <div data-testid={"browseClassesShimmer"} className="browseShimmer mobileScroll m-t-15 m-t-xs-32 m-b-xs-10">
            {!props.fromLandingPage && <Skeleton variant="text" width={'30%'} height={25} />}
            {!props.fromLandingPage && <Skeleton variant="text" width={'40%'} height={15} />}
            <div item className="mobileScroll m-b-10">
                <div container direction="row" className={`list squareImageContainer m-t-5 ${ props.fromLandingPage === true || props.fromClassFinder === true  ? "list-browseClasses-landing" : "list-browseClasses" }`} spacing={4}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() =>
                        <div item key={Math.random()}>
                                <Skeleton variant="rect" className={`browseClasses imageHolder ${ props.fromLandingPage === true || props.fromClassFinder === true ? "browseClasses-landing": ""}`} />
                                <div className='imageHolder'>
                                <div className="overlay" style={{ maxWidth: "173px" }}>
                                    <div className="bottomText text-white">
                                        <Skeleton variant="text" width={'50%'} height={13} className="m-b-10 m-b-xs-10" />
                                    </div>
                                </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
}
export default BrowseClassesShimmer;
