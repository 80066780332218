import React from 'react'

function VideoComponent(props) {

    const { children } = props;
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )


};
//RR || It will prevent re-initilization of Video Js.
export default React.memo(VideoComponent, (prevProps, nextProps) => prevProps.isVideoInit === nextProps.isVideoInit);