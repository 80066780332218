import { useDispatch, useSelector } from "react-redux";
import {redirectForSSO} from "../store/actions/auth"

const useClubCardNew = () => {
    const dispatch = useDispatch();
    const useClubCardNewState = () => ({
        // eslint-disable-next-line eqeqeq
        isAuthenticated : useSelector(state => state.auth.token != "")
    })
    const useClubCardNewDispatch = () => ({
        redirectForSSOClinent: (callbackFn)=> {
            dispatch(redirectForSSO(callbackFn))
        },
    })
    return {useClubCardNewState, useClubCardNewDispatch}
}
export {useClubCardNew}