import { useDispatch } from "react-redux";
import { fetchOnDemandClasses } from "../store/actions/ondemand"

const useBrowseCollections = () => {
    const dispatch = useDispatch();
    const useBrowseCollectionsDispatch = () => ({
        fetchOnDemandClasses: (userId)=> {
            dispatch(fetchOnDemandClasses(userId))
        }
    })
    return {useBrowseCollectionsDispatch}
}
export {useBrowseCollections}