import { useDispatch, useSelector } from "react-redux";
import { FetchLSConnectList, FetchClubLiveConnect, saveSelectedDate, resetFetchClubLiveConnect } from "../store/actions/ondemand"

const useLiveStreamList = () => {
    const dispatch = useDispatch();

    const useLiveStreamListState = () => ({
        clubListClasses : useSelector(state => state.onDemand.ListClasses),
        recordedClasses : useSelector(state => state.onDemand.clubClasses),
        isLoading : useSelector(state => state.onDemand.loading),
        isSelectedDate : useSelector(state => state.onDemand.selectedDate),
        lsloading : useSelector(state => state.onDemand.lsloading),
        fetchFailed : useSelector(state => state.onDemand.fetchFailed)
    })
    const useLiveStreamListDispatch = () => ({
        saveSelectedDate: (date)=> {
            dispatch(saveSelectedDate(date))
        },
        fetchLSConnectList: (page)=> {
            dispatch(FetchLSConnectList(page))
        },
        resetFetchClubLiveConnect: ()=> {
            dispatch(resetFetchClubLiveConnect())
        },
        fetchClubLiveConnect: (page)=> {
            dispatch(FetchClubLiveConnect(page))
        }
    })

    const initialState = {
        SelectedDate : (new Date()),
        showAllData : true,
        allData : [],
        EventData : null,
        pageCount : 0,
        apiLiveSchedulePageCount : 0,
        loadMoreAllCalled : false,
        paginatedDataAll : [],
        paginatedData : [],
        allRecordsLeft : 0,
        hasMoreData : false,
        apiOnDemandPageCount : 1,
        paginatedDataOnDemand : [],
        hasMoreDataOnDemand : false,
        allRecordsLeftOnDemand : 0,
        onDemandTotalPages : 0  
    }

    const useLiveStreamListAction = {
        UPDATE_LIVESTREAMLISTACTION : 'UPDATE_LIVESTREAMLISTACTION'
    }

    const useLiveStreamListReducer = (state,action) => {
        const {type, payload} = action;

        switch (type) {
            case useLiveStreamListAction.UPDATE_LIVESTREAMLISTACTION:
                return {...state, ...payload}

            default:
               return state;
        }       
    };

   const setReducerData = (liveStreamListDispatch,payload) =>{
    liveStreamListDispatch({
        type: useLiveStreamListAction.UPDATE_LIVESTREAMLISTACTION,
        payload:payload
    })
   }

    return {useLiveStreamListState,
         useLiveStreamListDispatch,
         liveStreamListReducer : {useLiveStreamListReducer, useLiveStreamListAction ,initialState ,setReducerData}}
}
export {useLiveStreamList}