import React from "react";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router'   
import { Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate"
import config from "../../assets/config.json"
import ResCard from "./newcard";

function ChannelsNewCard(props) { 
    
    const collection = {
        collectionType: "channel",
        collectionItems:[props.data]
    }
    const checkFavStatus = () => {
        let classes = props.data.title;
        classes[0].favourite = !classes[0].favourite
    }
    const cardVersion = config.cardVersion
    return (
        // <div className="channelHomeContainer">
        //     <Grid container item className="align-left">
        //         <Grid item lg={3} md={3} xs={3}>
        //             <img
        //             alt="chanel-img"
        //             onClick={props.redirectTo}
        //             src={props.profileImageUrl} 
        //             className="channel-profileImg"
        //             />                                    
        //         </Grid>  
        //         <Grid item lg={9} md={9} xs={9} className="p-l-xs-15  v-center align-left align-middle">
        //                     <Typography variant="subtitle2" className="pointer-cursor"  onClick={props.redirectTo}>
        //                         {props.displayName}
        //                     </Typography>
                            
        //                     <Typography variant="h4" className="linkText">
        //                         <span className="makeTextClickable" onClick={props.redirectTo}>
        //                             {Translate({id:"Channels.ViewChannel"})}
        //                             </span>
        //                     </Typography>
                        
        //         </Grid>
        //     </Grid>  
        //     <div container item  className="align-left" style={{marginTop:"0px"}}>
        //         <div item>
        //             <div>
        //             <Typography variant="h4"
        //             className="text-gray m-t-15 m-t-xs-15 channel-desc align-left">
        //             {props.description}
        //         </Typography>
        //         </div>
        //             <div>
        //             <Typography variant="h4" className="m-t-15 m-t-xs-15 channels-subhead text-gray">
        //                 {Translate({
        //                     id:"Channels.ChannelLatest"
        //                 })}
        //         </Typography> 
        //         </div> 
        //         </div>
        //     </div>
        //     <div>
            <ResCard
                takeUserToClassDetail={props.gotoPlayer}
                collection = {collection}
            />
        // </div>
        // </div>
    )
}

export default withRouter(ChannelsNewCard)
