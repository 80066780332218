import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
  dynamicShortLink: null,
  fetchShortLinkLoading: false,
};

const fetchDynamicShortLinkStart = (state, action) => {
  return updateObject(state, {
    fetchShortLinkLoading: true,
  });
};

const fetchDynamicShortLinkSuccess = (state, action) => {
  return updateObject(state, {
    dynamicShortLink: action.shortLink,
    fetchShortLinkLoading: false,
  });
};

const fetchDynamicShortLinkFail = (state, action) => {
  return updateObject(state, {
    fetchShortLinkLoading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DYNAMIC_DEEPLINK_START:
      return fetchDynamicShortLinkStart(state, action);
    case actionTypes.FETCH_DYNAMIC_DEEPLINK_SUCCESS:
      return fetchDynamicShortLinkSuccess(state, action);
    case actionTypes.FETCH_DYNAMIC_DEEPLINK_FAIL:
      return fetchDynamicShortLinkFail(state, action);
    default:
      return state;
  }
};

export default reducer;
