import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
    moreClassesData: [],
    loading: false,
};

const setMoreClasses = (state, action) => {
    return updateObject(state, {
        moreClassesData: action.value,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MORE_CLASSES_START:
            return updateObject(state, {
                moreClassesData: [],
                loading: true
            });
        case actionTypes.GET_MORE_CLASSES:
            return setMoreClasses(state, action);
        default:
            return state;
    }
};
export default reducer;
