
import { create } from 'apisauce';
//import { encode as btoa } from "base-64";
import Environment from "./config";


const baseURL = Environment.BASE_URL;


const generateApiEnvironment = (isStaff = null) => {
    let exerpApi = null;
    if (isStaff === null) {

        exerpApi = create({
            baseURL: baseURL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': 'Basic jkl23r4nNJKRHn@(3KJn)jkrfnn2jkmn!BN@Bmnn2:Nbnfm23bmnn@mBFM4nm3fb34n^@fb!34jdwebjcbvk2'
            }
        });
    }
    else if (isStaff === true) {
        exerpApi = create({
            baseURL: baseURL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': 'Basic jkl23r4nNJKRHn@(3KJn)jkrfnn2jkmn!BN@Bmnn2:Nbnfm23bmnn@mBFM4nm3fb34n^@fb!34jdwebjcbvk2'
            }
        });
    }
    else if (isStaff === false) {
        exerpApi = create({
            baseURL: baseURL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': 'Basic jkl23r4nNJKRHn@(3KJn)jkrfnn2jkmn!BN@Bmnn2:Nbnfm23bmnn@mBFM4nm3fb34n^@fb!34jdwebjcbvk2'
            }
        });
    }
    return exerpApi;
}
/* 
function makeSecurityString() {

    try {

        let auth = localStorage.getItem("thirdPartyAuth");
        const signature = auth.userId + ":" + auth.token;
        const base64Signature = "Basic " + signature// btoa(signature);

        return base64Signature;
    } catch (error) {

    }
}

function makeExerpSecurityString() {
    try {
        const signature = Environment.THIRD_PARTY_KEY + ":" + Environment.THIRD_PARTY_SECRET;
        const base64Signature = "Basic " + signature//btoa(signature);
        return base64Signature;
    } catch (error) {

    }
}
 */
export default generateApiEnvironment;











// const clientID = config.API_KEY;
// const clientSecret = config.API_SECRET;

// const signature = clientID + ":" + clientSecret;
// const base64Signature = btoa(signature);


// export const Authorization = 'Basic ' + base64Signature;

// const URL = config.BASE_URL;

// const api = create({
//     baseURL: URL,
//     Accept: 'application/json',
//     headers: {
//         'content-type': 'application/json',
//     }
// });


// /* api.addRequestTransform(request => {
//      if (request.url.indexOf("oauth") == -1 && request.headers["Authorization"]==undefined) {
//          request.headers["Authorization"] = Authorization;
//      }
//      if (request.url.indexOf("oauth") == -1) {


//      }

//  })*/
// export default api;


