import Tc from "../assets/tc/tc"
import { GetConsentDetail  } from "../store/actions/auth";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {injectIntl} from 'react-intl'; 

const mapStateToProps = state => {
    return {
      consents: state.auth.consents
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      loadConsentDetail: () => dispatch(GetConsentDetail()),
    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(injectIntl(Tc)));