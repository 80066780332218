import React,{ useEffect, useRef }  from 'react'
import { useIconSize } from '../utils/svgSize';

function IconRigntArrow(props) {
    const config = require("../assets/config.json")
    const getSize =useIconSize(["24px","32px"],props.id);
    return (
        <span className='right-arrow'>
            <svg id={props.id} width={getSize} height={getSize} viewBox="0 -2 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path d="M26.388 14.66H8.828l3.84-3.84a1.328 1.328 0 0 0-.94-2.27c-.352 0-.69.14-.94.39l-6.12 6.12c-.52.52-.52 1.36 0 1.88l6.12 6.12c.52.52 1.36.52 1.88 0 .52-.52.52-1.36 0-1.88l-3.84-3.853h17.56c.734 0 1.334-.6 1.334-1.334 0-.733-.6-1.333-1.334-1.333z" id={"9x1meoa6aa"}/>
                </defs>
                <g transform="matrix(-1 0 0 1 32 0)" fill="none" fill-rule="evenodd">
                    <mask id={"ruvua1lhhb"} fill="#fff">
                        <use xlinkHref={"#9x1meoa6aa"}/>
                    </mask>
                    <g mask={"url(#ruvua1lhhb)"} fill={config.primaryColor}>
                        <path d="M0 0h32v32H0z"/>
                    </g>
                </g>
            </svg>
        </span>
  )
}

export default IconRigntArrow