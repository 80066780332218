import ChannelView from "../containers/Channels/ChannelView"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {injectIntl} from 'react-intl';

const mapStateToProps = state => {
    return {
        collectionObject: state.onDemand.onDemandCollection,
    };
};

// export default withRouter(connect(mapStateToProps, null)(ChannelView));
export default connect(
    mapStateToProps,
    null
  )(withRouter(injectIntl(ChannelView)));