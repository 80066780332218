import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
    isAllChannelsLoading: false,
    allChannels: null,
    isClassesLoading: false,
    classes: null,
    moreClasses:null,
    classesData:null,
    classLoadingFailed: false
};

const fetchAllChannelsStart = (state, action) => {
    return updateObject(state, {
        isAllChannelsLoading: true
    });
};

const fetchAllChannelsSuccess = (state, action) => {
    return updateObject(state, {
        allChannels: action.channels,      
        isAllChannelsLoading: false  
    });
};
  
const fetchAllChannelsFail = (state, action) => {
    return updateObject(state, {
        isAllChannelsLoading: false
    });
};

const fetchClassesForChannelStart = (state, action) => {
    return updateObject(state, {
        isClassesLoading: true,
        classLoadingFailed: false
    });
};

const fetchClassesForChannelSuccess = (state, action) => {
    return updateObject(state, {
        classes: action.classes,    
        isClassesLoading: false,  
        classLoadingFailed: false
    });
};

const fetchMoreClassesForChannelStart = (state, action) => {
  return updateObject(state, {
      isClassesLoading: true,
      classLoadingFailed: false
  });
};

const fetchMoreClassesForChannelSuccess = (state, action) => {
  return updateObject(state, {
      moreClasses: action.classes,    
      isClassesLoading: false,  
      classLoadingFailed: false
  });
};

const fetchMoreClassesForChannelFail = (state, action) => {
return updateObject(state, {
    moreClasses:null,
    isClassesLoading: false,
    classLoadingFailed: true
});
};

const fetchClassesSuccess = (state, action) => {
    return updateObject(state, {
        classesData: action.classes,    
        isClassesLoading: false,  
        classLoadingFailed: false
    });
};
  
const fetchClassesForChannelFail = (state, action) => {
    return updateObject(state, {
        classes:null,
        isClassesLoading: false,
        classLoadingFailed: true
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
      //All Channels
      case actionTypes.FETCH_ALLCHANNELS_START:
        return fetchAllChannelsStart(state, action);
      case actionTypes.FETCH_ALLCHANNELS_SUCCESS:
        return fetchAllChannelsSuccess(state, action);
      case actionTypes.FETCH_ALLCHANNELS_FAIL:
        return fetchAllChannelsFail(state, action);
      case actionTypes.FETCH_CLASSESFORCHANNEL_START:
        return fetchClassesForChannelStart(state, action);
      case actionTypes.FETCH_CLASSES_SUCCESS:
          return fetchClassesSuccess(state, action);
      case actionTypes.FETCH_CLASSESFORCHANNEL_SUCCESS:
        return fetchClassesForChannelSuccess(state, action);
      case actionTypes.FETCH_CLASSESFORCHANNEL_CLEAR:
        return {...state, classes: null};
      case actionTypes.FETCH_CLASSESFORCHANNEL_FAIL:
        return fetchClassesForChannelFail(state, action);
      case actionTypes.FETCH_MORECLASSESFORCHANNEL_START:
        return fetchMoreClassesForChannelStart(state, action);
      case actionTypes.FETCH_MORECLASSESFORCHANNEL_SUCCESS:
        return fetchMoreClassesForChannelSuccess(state, action);
      case actionTypes.FETCH_MORECLASSESFORCHANNEL_FAIL:
        return fetchMoreClassesForChannelFail(state, action);
      default:
        return state;
    }
  };
  
  export default reducer;