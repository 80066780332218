import ChannelsCollection from "../containers/Channels/ChannelsCollection"
import {
    fetchAllChannels,
  } from "../store/actions/channels";

  import { connect } from "react-redux";
  import { withRouter } from 'react-router'
  import {injectIntl} from 'react-intl'; 
  import { FETCH_CLASSESFORCHANNEL_CLEAR } from "../store/actions/actionTypes";

const mapStateToProps = (state) => {
    return {
      allChannels: state.channels.allChannels,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchAllChannels: () => dispatch(fetchAllChannels()),
      clearClassData: () => dispatch({ type: FETCH_CLASSESFORCHANNEL_CLEAR})
    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(injectIntl(ChannelsCollection)))