import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import TncLink from "../../containers/TermsAndConditions/TncLink";
import HomePage from "../../hoc/HomePageContainer";
import OnDemandPage from "../../containers/OnDemandPage/OnDemandPage";
import BrowseCollections from "../../containers/PlayerAndCorousel/views/ClasslistView/BrowseCollections"
import BrowseCollectionsList from "../../containers/PlayerAndCorousel/views/ClasslistView/BrowseCollectionsList"
// import ClubPage from "../../containers/LiveConnect/ClubPage"
import ChannelsCollection from "../../hoc/ChannelsCollectionContainer"
import ChannelView from "../../hoc/ChannelViewContainer"
import WrapperList from "../../containers/LiveConnect/WrapperList"
import LiveStreamPlayer from "../../containers/LiveStreaming/LiveStreamPlayer"
import Categories from "../../containers/BrowseClasses/Categories"
import PaymentDone from "../../containers/Stripe/PaymentDone";
import Routes from './Routes'
import DefaultClassesPage from "../../containers/BrowseClasses/DefaultClassesPage"
import Loading from "../spinner/ComponentLoader";
import ProfileWrapper from "../../containers/User/ProfileWrapper";

const config = require('../../assets/config.json');


const delay = 1500;

const Notfound = Loadable({
  loader: () => import("../404/404"),
  loading: Loading,
  delay,
});

const Error = Loadable({
  loader: () => import("../Error"),
  loading: Loading,
  delay,
});

const forgetPassword = Loadable({
  loader: () => import("../../hoc/ForgetPasswordContainer"),
  loading: Loading,
  delay,
});

const updateUserDetails = Loadable({
  loader: () => import("../../hoc/UpdateAccountContainer"),
  loading: Loading,
  delay,
});

const contact = Loadable({
  loader: () => import("../../hoc/ContactContainer"),
  loading: Loading,
  delay,
});

const ClassListViewRecent = Loadable({
  loader: () =>
    import(
      "../../hoc/ClasslistViewRecentContainer"
    ),
  loading: Loading,
  delay,
});

// const ClassListViewSearch = Loadable({
//   loader: () =>
//     import(
//       "../../containers/PlayerAndCorousel/views/ClasslistView/ClasslistViewSearch"
//     ),
//   loading: Loading,
//   delay,
// });

const ClassCollection = Loadable({
  loader: () =>
    import(
      "../../hoc/NewCollectionPagesContainer"
    ),
  loading: Loading,
  delay,
});
const ClassListViewFav = Loadable({
  loader: () =>
    import(
      "../../hoc/ClasslistViewFavouriteContainer"
    ),
  loading: Loading,
  delay,
});

const ChannelPlayer = Loadable({
  loader: () =>
    import("../../hoc/ChannelPlayerContainer"),
  loading: Loading,
  delay,
});

const Player = Loadable({
  loader: () =>
    import("../../hoc/PlayerContainer"),
  loading: Loading,
  delay,
});

const SubscribePage = Loadable({
  loader: () => import("../../hoc/SubscribeContainer"),
  loading: Loading,
  delay,
});

const CheckOutPage = Loadable({
  loader: () => import("../../hoc/CheckOutPageContainer"),
  loading: Loading,
  delay,
});

const SigninPage = Loadable({
  loader: () => import("../../hoc/SignInContainer"),
  loading: Loading,
  delay,
});
const OauthCallbackPage = Loadable({
  loader: () => import("../../containers/Oauth/Callback"),
  loading: Loading,
  delay,
});

const UserAccount = Loadable({
  loader: () => import("../../hoc/AccountContainer"),
  loading: Loading,
  delay,
});

const Signup = Loadable({
  loader: () => import("../../hoc/SignupContainer"),
  loading: Loading,
  delay,
});

//By Nikhil on 26h Nov, 2019
//This component/method is used to restrict the user from accessing the direct routes on the basis of checkStatusTag
// checkStatusTag is configured in config.json and it will redirect the user to signIn page. 
const PrivateRoute = ({ component: Component, checkStatusTag, ...rest }) => (
  <Route {...rest} render={(props) => (
    checkStatusTag === false
      ? <Redirect to='/signin' /> : <Component {...props} />
  )} />
)
class Router extends Component {
  componentDidMount() {
    let url = window.location.pathname;
    let splittedUrl = url.split("/");
    if (splittedUrl.includes("ondemand")) {
      //checking old deeplink
      if (splittedUrl.includes("deep")) {
        let indexOfClassId = splittedUrl.indexOf("deep") + 1;
        if (splittedUrl[indexOfClassId] !== undefined) {
          window.location.pathname = `/classes/${splittedUrl[indexOfClassId]}`
        }
      } else {


        let index = splittedUrl.indexOf("ondemand");
        splittedUrl[index] = "home";
        let newUrl = splittedUrl.join("/");
        window.location.pathname = newUrl;
      }
    }
  }
  render() {
    window.scrollTo(0, 0);
    return (
      <Route
        render={({ location }) => (
          <Switch location={location}>
            <Route path={Routes.oldOnDemandClassPlayerRoute} exact component={OnDemandPage} />
            <Route path={`${Routes.categories}:id`} exact component={Categories} />
            <Route path="/tc" component={TncLink} />
            <Route path="/channels" exact component={()=><ChannelsCollection/>} />
            <Route path={`${Routes.channelDetailView}:id`} exact component={()=><ChannelView/>} />
            <Route path="/channels" exact component={ChannelPlayer} />
            <Route path="/success" component={PaymentDone} />

            <Route path={`${Routes.liveSchedulePlayerRoute}:id`} exact component={LiveStreamPlayer} />
            <Route path={`${Routes.liveDetail}:id`} exact component={LiveStreamPlayer} />            
            <Route path={Routes.connectClasses} exact component={WrapperList}></Route>
            <Route path={`${Routes.connectClassPlayerRoute}:id`} exact component={Player} />
            {/* <Route path={`${Routes.connect}:type`} exact component={WrapperList} /> */}

            <Route path={`${Routes.connect}schedule`} exact component={WrapperList} />
            <Route path={`${Routes.connect}classes`} exact component={WrapperList} />
            <Route path={`${Routes.connect}:id`} exact component={Player} />



            <Route path={`${Routes.collectionViewRoute}:id`} exact component={ClassCollection} />
            <Route path={Routes.collections} exact component={BrowseCollections} />
            
            {/* <Route path="/terms-and-conditions" exact component={TermsAndCondition} /> */}
            {/* <Route path="/ondemand" exact component={OnDemandPage} /> */}
            <Route path={Routes.classListViewRoute} exact component={DefaultClassesPage} />
            <Route path={Routes.searchRoute} exact component={DefaultClassesPage} />
            {/* <Route path={Routes.searchRoute} exact component={ClassListViewSearch} /> */}
            <Route path={Routes.favClassRoute} exact component={ProfileWrapper} />
            <Route path="/ondemand/fav" exact component={ProfileWrapper} />
            <Route path={Routes.recentActivityRoute} exact component={ProfileWrapper} />
            <Route path={Routes.recentActivityRouteV2} exact component={ProfileWrapper} />
            <Route path={Routes.favClassRouteV2} exact component={ProfileWrapper} />
            <Route path={`${Routes.onDemandClassPlayerRoute}:id`} exact component={Player} />
            <Route path={`${Routes.channelsPlayer}`} component={ChannelPlayer} />
            <Route path={Routes.browseCollection} exact component={BrowseCollectionsList} />
            <Route path="/signin" exact component={SigninPage} />
            <Route path="/callback" exact component={OauthCallbackPage} />
            <PrivateRoute path="/signup" component={Signup} checkStatusTag={config.showSignUpPage} />
            <Route path="/subscribe" exact component={SubscribePage} />
            <Route path="/subscribe/CheckOut" exact component={CheckOutPage} />
            <Route path="/user/account" exact component={ProfileWrapper} />
            <Route path="/contact" exact component={contact} />
            <PrivateRoute path="/user/update" exact component={ProfileWrapper} checkStatusTag={config.showSignUpPage} />
            <Route path="/ondemand/player/deep/:classId" exact component={Player} />
            <PrivateRoute path="/user/forget/:token" exact component={forgetPassword} checkStatusTag={config.showForgetPasswordPage} />
            <Route path={Routes.homePage} exact component={OnDemandPage} />
            <Route path={Routes.classListViewRoute} exact component={OnDemandPage} />
            <Route path="/error" exact component={Error} />

            <Route path="/" exact component={HomePage} />
            {/*v2 for backward compatibility of deeplinks*/}
            <Route path={`${Routes.collectionViewRouteV2}:id`} exact component={ClassCollection} />
            <Route path={`${Routes.channelDetailView}:id`} exact component={Player} />
            <Route component={Notfound} />
          </Switch>
        )}
      />
    );
  }
}

export default props => <Router />;
/* Rendering any components inside the Switch that isn't a Route causes buggy behaviour so here I'm wrapping the component in a UserConsumer */
