import React, {Fragment, useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Translate } from "../../utils/Translate";
import { Grid, Typography } from "@material-ui/core";
import Routes from '../../components/Router/Routes';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {getTranslatedFile } from '../../utils/utilityfunctions';
import { useBrowseClasses } from "../../hooks/useBrowseClasses";
import IconRigntArrow from '../../CommonImages/icon-rightArrow';

const config = require("../../assets/config.json");

const BrowseClasses = (props) => {
  const { useBrowseClassesState, useBrowseClassesDispatch } = useBrowseClasses();
  const { metadata } = useBrowseClassesState()
  const { resetBrowseClassData,setClassesSearchData,setPaginatedData,resetFilters } = useBrowseClassesDispatch()
  const [categories, setCategories] = useState([])
  const [showArrow] = useState(props.showArrow)

  useEffect(() => {
    if (metadata?.categories&&metadata.categories.length>0) {
      const language = getTranslatedFile()
      /// commenting below line as it's crashing for ZERO category Id
      //let sortedCategories=metadata.categories.sort((a,b)=>language.categoryName[a.id].localeCompare(language.categoryName[b.id]))
      let sortedCategories=metadata.categories.sort((a, b) => {
        try{
          const categoryNameA = language.categoryName[a.id];
          const categoryNameB = language.categoryName[b.id];
          if (categoryNameA && categoryNameB) {
            return categoryNameA.localeCompare(categoryNameB);
          }
          return false;
        }
        catch(e){
          return false;
        }
      });
      setCategories(sortedCategories)
    }
  }, [metadata])

  const navigateToBrowseClasses=(category)=>{
    resetBrowseClassData()
    setClassesSearchData()
    setPaginatedData()
    resetFilters()
    props.history.push({ pathname: `${Routes.categories}${category.id}`});
  }

  const getCategoryImage = (imageName) => {
    const bgImage =require(`../../assets/images/${imageName}.jpg`)
      try {
        return bgImage;
      } catch {
        return require(`../../assets/images/1001.jpg`)
      }
  }
  return (
    <Fragment>
      {categories && categories.length > 1 && <div item className={config.showLiveEvent? "m-t-60 mobileScrollParent" : props.fromLandingPage !== true ? "m-t-30 mobileScrollParent": "m-t-60 mobileScrollParent"}>
      {props.fromLandingPage !== true && <div className="m-b-32 m-b-15">
            <div className={showArrow?"align-left makeTextClickable adjustTitleWidth m-b-7 d-flex ":"align-left adjustTitleWidth m-b-7 d-flex"}
              onClick={() => { props.history.push({ pathname: Routes.classListViewRoute }); }}>
              <h1 className={(config.showLiveEvent||showArrow)?"align-left clear font-h1":"align-left clear font-h1"}>
                {Translate({ id: "homePage.BrowseClassesTitle" })}
              </h1>
              {showArrow && <IconRigntArrow id='arrow-4' />}
            </div>
              <h6 className="text-gray font-h6 align-left "  >
                {Translate({ id: "homePage.BrowseClassesDescription" })}
              </h6>
          </div>}
          {/* <div item className="m-b-10 m-b-xs-10">
            <Typography variant="h6" className={(config.showLiveEvent||showArrow)?"text-gray align-left":"text-gray"}>
              {Translate({ id: "homePage.BrowseClassesDescription" })}
            </Typography>
          </div> */}
        <div item className={(!config.showLiveEvent&&!showArrow)? (props.fromLandingPage !== true ? "mobileScroll m-b-10 m-t-15":"mobileScroll m-t-15"): (props.fromLandingPage !== true ?"mobileScroll m-b-10 m-t-15":"mobileScroll m-t-15")}>
          <div className="squareImageContainer" spacing={4}>
             {categories.length > 1  &&
             <div className={`list ${ props.fromLandingPage === true || props.fromClassFinder === true  ? "list-browseClasses-landing" : "list-browseClasses" }`}>
              {categories.map((category) =>
             <div className={`cardPos cardSize browseClasses ${ props.fromLandingPage === true || props.fromClassFinder === true ? "browseClasses-landing": ""}`}>
              <div data-testid={"browseCategoryClick"}  class="imageHolder full-overlay makeTextClickable" style={{backgroundImage:`url(${getCategoryImage(category.id)})`}}
              onClick={() => { navigateToBrowseClasses(category) }}>
              <div className="overlay" >
                    <div className="bottomText text-white">
                      <Typography variant="body2" className="m-b-10 m-b-xs-10 ">
                        {/* {category.name} */}
                        {Translate({ id: `categoryName.${category.id}`})}
                      </Typography>
                    </div>
                </div>
              </div>
                </div>)
              }</div>
              }



          </div>
        </div>
        </div>}
        </Fragment>
  )
}

export default withRouter(BrowseClasses);
